import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Cart } from "../../component/Cart/Cart";
import { MContainer, MLink, ShowAllLink } from "../../element/Elemens";
import { AiOutlineMessage } from "react-icons/ai";
import "../../assets/scss/_seller_adres.scss";
import { useSelector } from "react-redux";
import brbalo from "../../assets/images/3d-rendering-loft-luxury-living-room-with-shelf-near-dining-table 2.png";
import {
  getShopDetail,
  getShopProducts,
  getShopReclama,
} from "../../redux/actions/shopActions";
// import { getProductOne } from "../../redux/actions/productActions";
import { useDispatch } from "react-redux";
import PreLoader from "../../component/PreLoader/PreLoader";
import parse from "html-react-parser";
import { useAsync } from "react-async";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
  History,
  Autoplay,
  Virtual,
  EffectCube,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import ErrorModal from "../../component/ErrorModal/ErrorModal";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
const URL = `${process.env.REACT_APP_API_DOMAIN}`;

const SellerAdres = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { reduxToken } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getShopDetail(id));
    dispatch(getShopProducts(id));
    dispatch(getShopReclama(id));
  }, [id]);
  const [slice, setSlice] = useState(5);

  const { loading } = useSelector((state) => state.shop);
  const shopOne = useSelector((state) => state.shop.data);
  const shopProducts = useSelector((state) => state.shop.list);
  const { shopReclama } = useSelector((state) => state.shop);
  const shopDesc = String(shopOne?.description);

  return (
    <>
      <ErrorModal
        showModal={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        text="Зарегистрируйте чтобы написать"
      />
      {loading && <PreLoader />}
      {!loading && (
        <div key={shopOne?.id} className="seller-adres">
          <Swiper
            modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
            spaceBetween={30}
            pagination={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
            }}
            navigation={true}
            speed={2000}
            // lazy={true}
            style={{ zIndex: "0" }}
            className="mySwiper2"
          >
            {shopReclama?.map((slide, idx) => (
              <SwiperSlide key={idx} className="flex justify-center relative">
                <div className="absolute w-[80%] mx-auto top-[120px]">
                  <div className="bg-[#ffffff99] inline-block mx-auto overflow-y-auto rounded-[8px] p-[12px]">
                    {parse(slide?.content)}
                  </div>
                </div>
                <img
                  src={URL + slide?.photo}
                  className="swiper-lazy rounded-2xl h-[460px] object-cover"
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="header absolute top-[120px]">
            <img
              className="shop-banner"
              src={URL + shopOne.photoBanner}
              alt=""
            />

            <div className="container mx-auto px-2 xl:px-12 md:px-4">
              <h1>{shopOne?.name}</h1>
              <p>{parse(shopDesc)}</p>
            </div>
          </div> */}
          <div className="container mx-auto px-4 xl:px-12 md:px-4 seller__container">
            <div className="seller__adress">
              <div className="box">
                <img
                  src={`${URL}${shopOne.photo}`}
                  alt="not found"
                  className="z-[2]"
                />
                <div>
                  <div className="user__name">
                    {shopOne.contact_user ? shopOne.contact_user : "Нет данных"}
                  </div>
                  <div className="flex items-center">
                    <AiOutlineMessage
                      className="write mr-2"
                      fill="#7AB94A"
                      size={24}
                    />
                    <div
                      onClick={() => {
                        if (!reduxToken) {
                          setShowErrorModal(true);
                        } else {
                          navigate("/message/type=shop/" + shopOne.user?.id);
                        }
                      }}
                      className="write__to-market"
                      style={{ color: "#7AB94A" }}
                    >
                      Написать в магазин
                    </div>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box__title">Юридический адрес</div>
                <p>{shopOne.shopSeller?.address_legal}</p>
              </div>
              <div className="box">
                <div className="box__title">Город</div>
                <p>Ташкент</p>
              </div>
              <div className="box">
                <div className="box__title">Отзывы</div>
                <p>{shopOne.review_count}</p>
              </div>
            </div>
          </div>
          <MContainer>
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 mb-12">
              {shopProducts?.slice(0, slice).map((product, idx) => (
                <Cart key={idx} product={product} />
              ))}
            </div>
            {slice !== shopProducts?.length && shopProducts?.length > 0 && (
              <button
                onClick={() => setSlice(shopProducts?.length)}
                className="show__all"
              >
                Показать еще
              </button>
            )}
          </MContainer>
        </div>
      )}
      {!loading && shopReclama?.length > 0 && (
        <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-4 mb-16 max-[640px]:px-4">
          {shopReclama?.map((item, idx) => (
            <Card key={idx}>
              <CardMedia
                sx={{ height: 220 }}
                image={URL + item?.photo}
                title="green iguana"
                className="object-contain"
                style={{ backgroundSize: "contain" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {parse(item?.content)}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
      {/* {loading && <PreLoader />}
      {!loading && (
        <div className="seller-adres">
          <div className="header relative">
            <img
              className="shop-banner"
              src={brbalo}
              alt=""
            />
            <div className="container mx-auto px-4 xl:px-12 md:px-4">
              <h1>
                MarketPlace misslighting
              </h1>
              <p>
                Получайте заказы от клиентов, привозите товар и получайте
                большую комиссию от продаж
              </p>
            </div>
          </div>
          <div className="container mx-auto px-4 xl:px-12 md:px-4 seller__container">
            <div className="seller__adress">
              <div className="box">
                <img src={brbalo} alt="not found" />
                <div>
                  <div className="user__name">
                    Ilhom Nasriddinov
                  </div>
                  <div className="flex items-center">
                    <AiOutlineMessage
                      className="write mr-2"
                      fill="#7AB94A"
                      size={24}
                    />
                    <MLink
                      to={"/message/type=shop/"}
                      className="write__to-market"
                      style={{ color: "#7AB94A" }}
                    >
                      Написать в магазин
                    </MLink>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box__title">Юридический адрес</div>
                <p>Tashkent</p>
              </div>
              <div className="box">
                <div className="box__title">Город</div>
                <p>Ташкент</p>
              </div>
              <div className="box">
                <div className="box__title">Отзывы</div>
                <p>130</p>
              </div>
            </div>
          </div>
          <MContainer>
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 mb-12">
              {shopProducts?.slice(0, slice).map((product, idx) => (
                <Cart key={idx} product={product} />
              ))}
            </div>
            {slice !== shopProducts?.length && (
              <button
                onClick={() => setSlice(shopProducts?.length)}
                className="show__all"
              >
                Показать еще
              </button>
            )}
          </MContainer>
        </div>
      )} */}
    </>
  );
};

export default SellerAdres;
