import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import { MdFavoriteBorder } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getProductOne } from "../../redux/actions/productActions";
import { createShop } from "../../redux/actions/shopActions";
const API = `${process.env.REACT_APP_API_DOMAIN}`;

const Shop = ({ shop, favourite, userId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [favorite, setFavorite] = useState(favourite);
  useEffect(() => {
    dispatch(getProductOne(id));
  }, []);
  const productOne = useSelector((state) => state.product.data);

  return (
    <div onChange={() => console.log(favourite)} className="seller-seller">
      {shop?.isFavorite === false ? (
        <MdFavoriteBorder
          onClick={() => dispatch(createShop({ shop_id: shop.id }))}
          className="fa-heart"
          fill="#ee4927"
          size={24}
        />
      ) : (
        <FaHeart
          onClick={() => dispatch(createShop({ shop_id: shop.id }))}
          className="fa-heart"
          fill="#EE4927"
          size={24}
        />
      )}
      {productOne.shop?.photo ? (
        <img src={`${API}${productOne.shop?.photo}`} alt="not found" />
      ) : (
        <img
          src="http://admin.chinni-buyum.uz//assets_files/images/user.png"
          alt="not found"
        />
      )}
      <div className="name__title mt-2">
        {productOne.shop?.contact_user ? productOne.shop?.contact_user : ""}
      </div>
      <div className="links">
        <Link to={"/message/type=shop/" + userId} className="write__to-market">
          <AiOutlineMessage className="write mr-2" fill="#EE4927" size={24} />
          Написать
        </Link>
        <Link
          to={"/selleradres/" + productOne.shop?.id}
          className="market__link"
        >
          Перейти в магазин
        </Link>
      </div>
    </div>
  );
};

export default Shop;
