import { Button, Pagination } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Cart } from "../../component/Cart/Cart";
import ProductSkelet from "../../component/loading/ProductSkelet";
import PreLoader from "../../component/PreLoader/PreLoader";
import NavbarMenu from "../../container/NavbarMenu";
import { MContainer } from "../../element/Elemens";
import { getProductsByFilter } from "../../redux/actions/filterActions";

const SearchProduct = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params, setParams] = useState({
    query: slug,
    page: 1,
    "per-page": 10,
  });
  useEffect(() => {
    let newParams = {
      ...params,
      page: 1,
      query: slug || "",
    };
    setParams(newParams);
    dispatch(getProductsByFilter(newParams));
  }, [slug]);

  const onPageChange = (event, value) => {
    let newParams = {
      ...params,
      page: value,
    };
    setParams(newParams);
    dispatch(getProductsByFilter(newParams));
  };

  const productFelter = useSelector((state) => state.filter.list);
  const { searchPagination } = useSelector((state) => state.filter);
  const { loading } = useSelector((state) => state.filter);

  return (
    <>
      <NavbarMenu />
      <MContainer>
        <div className="text-center flex items-center justify-center flex-wrap text-gray-500 mt-4">
          Поиск по запросу "{" "}
          <div className="text-black font-bold">
            {slug ? slug : "Все товары"}
          </div>{" "}
          "
          <Button
            onClick={() => navigate("/products-by-photo")}
            className="!ml-auto"
            variant="outlined"
            color="warning"
          >
            Поиск по картинкам
          </Button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 mb-12 mt-6">
          {productFelter?.map((product) => (
            <Cart product={product} />
          ))}
          {loading && <ProductSkelet length={10} />}
        </div>
        {productFelter?.length > 0 && (
          <div className="flex items-center justify-center my-8">
            <Pagination
              onChange={onPageChange}
              count={searchPagination?.pageCount}
              page={searchPagination?.currentPage}
              defaultPage={searchPagination?.currentPage}
              color="warning"
            />
          </div>
        )}
      </MContainer>
    </>
  );
};

export default SearchProduct;
