import React, { useState, useEffect } from "react";
import "../assets/scss/_navbar.scss";
import {
  MContainer,
  MLink,
  MNavbar,
  MNavbarTop,
  MNav,
} from "../element/Elemens";
import { HiLocationMarker } from "react-icons/hi";
import { IoIosNotificationsOutline, IoMdClose } from "react-icons/io";
import { BiCategoryAlt } from "react-icons/bi";
import { ImCart, ImInsertTemplate } from "react-icons/im";
import { BiGitCompare, BiUserCheck } from "react-icons/bi";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoIosSearch, IoIosClose } from "react-icons/io";
import { AiOutlineShopping, AiOutlineRight } from "react-icons/ai";
import { FaUserAlt, FaRegUser } from "react-icons/fa";
import { FiMenu, FiSearch } from "react-icons/fi";
import { BsSuitHeartFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/images/POWERED BY MISS LIGHTING.svg";

import notificationImg from "../assets/images/11 122001.png";
import { Badge, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/authActions";
import asd from "../assets/images/43b882_191c44e0347c4b88bd6df26ba5cbf3b7_mv2 2.png";
import { AiOutlineCamera } from "react-icons/ai";
import basketIcon from "../assets/images/Buy.svg";
import {
  getCategoriesAll,
  getCategory,
  getRegions,
  getRegionsSub,
  getSubCategoriesAll,
  subCategoryFilter,
} from "../redux/actions/categoryActions";
import { getFavoriteAll } from "../redux/actions/favoriteActions";
import { getCart } from "../redux/actions/cartActions";
import {
  callCenter,
  getCurrency,
  getMe,
  getNewNotification,
  getNotification,
  setReaded,
} from "../redux/actions/userActions";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import {
  getProductsByFilter,
  getShopsByFilter,
} from "../redux/actions/filterActions";
import PreLoader from "../component/PreLoader/PreLoader";
import { getShopFavoriteAll } from "../redux/actions/shopActions";
import bulut1 from "../assets/images/bulutt.png";
import { toast } from "react-toastify";
import UzFlag from "../assets/images/uz.svg";
import RuFlag from "../assets/images/ru.svg";
import EnFlag from "../assets/images/gb-eng.svg";
import ProfileModal from "../component/NoneProfileModal/ProfileModal";
import { Box, Modal, Skeleton } from "@mui/material";
// const token = window.localStorage.getItem("missLightingToken");
const URL = "http://admin.chinni-buyum.uz/";
const language = window.localStorage.getItem("missLighting-Content-language");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "#fff",
  boxShadow: 24,
  padding: "24px",
};

const Navbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [filterSearch, setFilterSearch] = useState("1");
  const [filterProduct, setFilterProduct] = useState({ query: "" });
  const [filterShop, setFilterShop] = useState({ query: "" });

  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [fixedNavbar, setFixedNavbar] = useState(false);
  const [fixedNavbarResponse, setFixedNavbarResponse] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [subCategoryState, setSubCategoryState] = useState();
  const [openProfile, setOpenProfile] = useState(false);
  const [categoryInput, setCategoryInput] = useState("");
  const [notification, setNotification] = useState(false);
  const [activeNot1, setActiveNot1] = useState(true);
  const [activeNot2, setActiveNot2] = useState(false);
  const [uopenMenuMobile, setUopenMenuMobile] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openMenuSearch, setOpenMenuSearch] = useState(false);
  const [currency2, setCurrency] = useState();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const handleShowProfileModal = () => {
    setShowProfileModal(false);
  };

  const handleNotification = () => {
    setNotification((value) => !value);
  };

  const changeScrollNavbar = () => {
    if (window.scrollY >= 45) {
      setFixedNavbar(true);
    } else {
      setFixedNavbar(false);
    }
  };

  window.addEventListener("scroll", changeScrollNavbar);
  const changeScrollNavbarResponse = () => {
    if (window.scrollY >= 50) {
      setFixedNavbarResponse(true);
    } else {
      setFixedNavbarResponse(false);
    }
  };
  window.addEventListener("scroll", changeScrollNavbarResponse);

  const handleSelected = () => {
    setOpenCategory((value) => !value);
    setSubCategoryState(false);
  };
  const handleNot1 = () => {
    setActiveNot1(true);
    setActiveNot2(false);
  };
  const handleNot2 = () => {
    setActiveNot2(true);
    setActiveNot1(false);
  };
  const openMenuMobile = (value) => {
    setUopenMenuMobile(value);
  };
  const openProfileMenuFun = (value) => {
    setOpenProfileMenu(value);
  };

  useEffect(() => {
    dispatch(getCategory());
    // dispatch(subCategoryFilter());
    dispatch(getFavoriteAll());
    dispatch(getShopFavoriteAll());
    dispatch(getCart());
    dispatch(getMe());
    dispatch(getShopsByFilter(filterShop));
    dispatch(callCenter());
    dispatch(getCurrency());
    // dispatch(getNotification());
    // dispatch(getNewNotification());
    dispatch(getRegions());
  }, []);

  const favoriteList = useSelector((state) => state.favorite.favoritiesList);
  const cartList = useSelector((state) => state.cart.list);
  const productFelter = useSelector((state) => state.filter.list);
  const shopFilter = useSelector((state) => state.filter.shopList);
  const navCategoryList = useSelector((state) => state.category.list);
  const subCategory = useSelector((state) => state.category.subCategory);
  const shop_favoriteList = useSelector((state) => state.shop.shopFavoriteList);
  const call_center = useSelector((state) => state.user.call);
  const currency = useSelector((state) => state.user.currencyList);
  const notifications = useSelector((state) => state.user.notificationList);
  const newNotifications = useSelector(
    (state) => state.user.newNotificationList
  );
  const token = window.localStorage.getItem("missLightingToken");
  const { reduxToken } = useSelector((state) => state.user);
  const regions = useSelector((state) => state.category.regions);
  const regions_sub = useSelector((state) => state.category.regions_sub);
  const { regionsLoading } = useSelector((state) => state.category);
  const contentRegion = window.localStorage.getItem(
    "missLighting-Content-region"
  );
  const flagStorage = window.localStorage.getItem(
    "missLighting-Content-language"
  );
  const regionId = regions.find((item) => item.id === Number(contentRegion));

  const handleLogout = () => {
    if (window.confirm("Вы точно хотите выйти?")) {
      navigate("/");
      dispatch(logout());
    }
  };

  const [regionModal, setRegionModal] = useState(false);
  const handleOpenRegionModal = () => setRegionModal(true);
  const handleCloseRegionModal = () => setRegionModal(false);

  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedRegionName, setSelectedRegionName] = useState("");
  const contentRegionName = window.localStorage.getItem(
    "missLighting-Content-region-name"
  );
  const handleBtcRegion = async (e) => {
    const asd = await e.target.selectedOptions[0].label;
    setSelectedRegion(e.target.value);
    setSelectedRegionName(asd);
  };

  return (
    <>
      <Modal open={regionModal} onClose={handleCloseRegionModal}>
        <Box style={style}>
          <div className="font-bold text-center text-xl">Выберите регион</div>
          <div className="text-center mt-3 mb-7">Это необходимо при заказе</div>
          <div className="delivery__select flex-col gap-4">
            <select
              onChange={(e) => dispatch(getRegionsSub(Number(e.target.value)))}
              className="select w-full"
            >
              <option value="">Выберите регион</option>
              {regions?.map((item, idx) => (
                <option value={item.id} key={idx}>
                  {item?.name}
                </option>
              ))}
            </select>
            {regions_sub?.length > 0 && (
              <select
                onChange={(e) => {
                  handleBtcRegion(e);
                }}
                className="select w-full"
              >
                <option value="">Выберите город</option>
                {regions_sub?.map((item, idx) => (
                  <option
                    selected={item.id == selectedRegion}
                    value={item?.id}
                    nameR={item?.name}
                    key={idx}
                  >
                    {item?.name}
                  </option>
                ))}
              </select>
            )}
            {selectedRegion && (
              <button
                onClick={() => {
                  window.localStorage.setItem(
                    "missLighting-Content-region",
                    selectedRegion
                  );
                  window.localStorage.setItem(
                    "missLighting-Content-region-name",
                    selectedRegionName
                  );
                  window.location.reload();
                }}
                className="text-white rounded py-3"
                style={{ background: "#131E3D" }}
              >
                Сохранить
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <div className="navbar">
        {/* <img className={`bulut1 absolute`} src={bulut1} alt="" />
        <img className={`bulut2`} src={bulut1} alt="" />
        <img className={`bulut3`} src={bulut1} alt="" />
        <img className={`bulut4`} src={bulut1} alt="" />
        <img className={`bulut5`} src={bulut1} alt="" />
        <img className={`bulut6`} src={bulut1} alt="" /> */}
        <ProfileModal
          showModal={showProfileModal}
          onClose={handleShowProfileModal}
          text={"Зарегистрируйтесь, чтобы посмотреть"}
        />
        <div className="navbar__nav">
          <MNavbarTop>
            <MContainer>
              <div className="navbar__item">
                <div className="nav__item ml-auto">
                  <div className="nav__item__language flex align-items-center">
                    {/* <div className="relative">
                      <IconButton onClick={handleNotification} sx={{ p: 0 }}>
                      {newNotifications?.length > 0 ? (
                        <Badge color="error" variant="dot">
                        <IoIosNotificationsOutline size={26} />
                          </Badge>
                        ) : (
                          <IoIosNotificationsOutline size={26} />
                          )}
                          </IconButton>
                          {notification ? (
                            <div
                            className="notification__dropdown"
                            style={{ opacity: "1", visibility: "inherit" }}
                            >
                            <div className="not-title mt-4">Уведомление</div>
                            <div className="not-tabs py-2">
                            <div
                              className={`not__tab mr-2 ${
                                activeNot1 ? "active" : null
                              }`}
                              onClick={handleNot1}
                            >
                              Все
                            </div>
                            <div
                              className={`not__tab mr-2 ${
                                activeNot2 ? "active" : null
                              }`}
                              onClick={handleNot2}
                            >
                              Непрочитанные
                            </div>
                          </div>
                          {activeNot1 ? (
                            <div>
                              <div className="flex items-center justify-between py-2">
                                <div className="subtitle">Новые</div>
                                <span className="subtitle">Все</span>
                              </div>
                              {notifications?.length < 1 && "Нет уведомление"}
                              {notifications?.map((not, idx) => (
                                <div
                                  key={idx}
                                  className="notification__link flex py-2"
                                >
                                  <div className="not__img">
                                    <img
                                      src={notificationImg}
                                      alt="not found"
                                    />
                                  </div>
                                  <div className="not__text">
                                    <b>
                                      {not.name} {not.lastname}
                                    </b>
                                    <span>
                                      отправил сообщение: "{not.message}"
                                    </span>
                                    <div className="notification__time">
                                      {not.date}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                          {activeNot2 ? (
                            <div>
                              <div className="flex items-center justify-between py-2">
                                <div className="subtitle">Новые</div>
                                <span className="subtitle">Непрочитанные</span>
                              </div>
                              {newNotifications?.length < 1 &&
                                "Нет уведомление"}
                              {newNotifications?.map((news, idx) => (
                                <a
                                  key={idx}
                                  href={`/notification/type=readed/` + news.id}
                                  onClick={() => {
                                    handleNotification();
                                    dispatch(setReaded({ id: news.id }));
                                  }}
                                  className="notification__link flex py-2"
                                >
                                  <div className="not__img">
                                    <img
                                      src={notificationImg}
                                      alt="not found"
                                    />
                                  </div>
                                  <div className="not__text new">
                                    <b>
                                      {news.name} {news.lastname}
                                    </b>
                                    <span>
                                      отправил сообщение: "{news.message}"
                                    </span>
                                    <div className="notification__time">
                                      {news.date}
                                    </div>
                                  </div>
                                </a>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div
                          className="notification__dropdown"
                          style={{ opacity: "0", visibility: "hidden" }}
                        ></div>
                      )}
                    </div> */}
                    <span
                      onClick={handleOpenRegionModal}
                      className="nav__item__location cursor-pointer"
                    >
                      <HiLocationMarker
                        size={14}
                        color="#131e3d"
                        className="nav__item__location__icons"
                      />
                      {regionsLoading ? (
                        <Skeleton width={120} height="100%" />
                      ) : (
                        <div>
                          {contentRegionName
                            ? contentRegionName
                            : "Выберите регион"}
                        </div>
                      )}
                    </span>
                    <div className="arrow-color-change mr-2">
                      <select
                        onChange={(e) => {
                          window.localStorage.setItem(
                            "missLighting-Content-currency",
                            e.target.value
                          );
                          window.location.reload();
                        }}
                        className="ml-4"
                      >
                        <option
                          value={window.localStorage.getItem(
                            "missLighting-Content-currency"
                          )}
                        >
                          {window.localStorage.getItem(
                            "missLighting-Content-currency"
                          )}
                        </option>
                        {/* {currency?.map((item, idx) => (
                          <option key={idx} value={item.name}>
                            {item.name}
                          </option>
                        ))} */}
                        {/* <option value="Сум">Сум</option> */}
                        {/* <option value="У.е.">У.е.</option> */}
                      </select>
                    </div>
                    <div className="arrow-color-change _language">
                      <div
                        className="pl-2 flex items-center"
                        style={{ minWidth: "65px", gap: "4px" }}
                      >
                        {flagStorage === "uz" && (
                          <img
                            style={{
                              minWidth: "18px",
                              height: "18px",
                              width: "18px",
                              objectFit: "cover",
                            }}
                            src={UzFlag}
                            alt=""
                          />
                        )}
                        {flagStorage === "ru" && (
                          <img
                            style={{
                              minWidth: "18px",
                              height: "18px",
                              width: "18px",
                              objectFit: "cover",
                            }}
                            src={RuFlag}
                            alt=""
                          />
                        )}
                        {flagStorage === "en" && (
                          <img
                            style={{
                              minWidth: "18px",
                              height: "18px",
                              width: "18px",
                              objectFit: "cover",
                            }}
                            src={EnFlag}
                            alt=""
                          />
                        )}
                        {window.localStorage.getItem(
                          "missLighting-Content-language"
                        )}
                      </div>
                      <div className="_language-dropdown">
                        <div
                          className="item"
                          onClick={() => {
                            window.localStorage.setItem(
                              "missLighting-Content-language",
                              "uz"
                            );
                            window.location.reload();
                          }}
                        >
                          <img value="uz" src={UzFlag} alt="" />
                          uz
                        </div>
                        <div
                          className="item"
                          onClick={() => {
                            window.localStorage.setItem(
                              "missLighting-Content-language",
                              "ru"
                            );
                            window.location.reload();
                          }}
                        >
                          <img value="ru" src={RuFlag} alt="" />
                          ru
                        </div>
                        <div
                          className="item"
                          onClick={() => {
                            window.localStorage.setItem(
                              "missLighting-Content-language",
                              "en"
                            );
                            window.location.reload();
                          }}
                        >
                          <img value="en" src={EnFlag} alt="" />
                          en
                        </div>
                      </div>
                      {/* <select
                        className="ml-4"
                        onChange={(e) => {
                          window.localStorage.setItem(
                            "missLighting-Content-language",
                            e.target.value
                          );
                          window.location.reload();
                        }}
                      >
                        <option
                          style={{ backgroundImage: `${UzFlag}` }}
                          value="ru"
                        >
                          ru
                        </option>
                        <option
                          style={{ backgroundImage: `${UzFlag}` }}
                          value="en"
                        >
                          en
                        </option>
                        <option
                          style={{ backgroundImage: `${UzFlag}` }}
                          value="uz"
                        >
                          uz
                        </option>
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>
            </MContainer>
          </MNavbarTop>
          <MNavbar
            positionFixed={fixedNavbar}
            positionFixedResponse={fixedNavbarResponse}
          >
            <MContainer>
              <div className="navbar__item__global">
                <div className="nav__item__brand">
                  <MLink to="/">
                    <div className="nav__logo">
                      <img
                        className={`${
                          fixedNavbar === true ? "hidden" : "block"
                        } nav__logo`}
                        src={Logo}
                        alt="Birmakon"
                      />
                    </div>
                  </MLink>
                  <div className="nav__item__category relative">
                    {openCategory ? (
                      <IoMdClose fill="#7AB94A" size={20} />
                    ) : (
                      <BiCategoryAlt fill="#7AB94A" size={20} />
                    )}
                    <div
                      onClick={handleSelected}
                      className="px-2 category__title"
                      style={{ color: "#7AB94A" }}
                    >
                      {language === "ru" && "Категории"}
                      {language === "uz" && "Kategoriya"}
                      {language === "en" && "Category"}
                    </div>
                    <IoMdArrowDropdown fill="#7AB94A" size={20} />
                  </div>
                </div>
                {openCategory && (
                  <div
                    onClick={() => setOpenCategory(false)}
                    style={{ zIndex: "9", background: "rgba(0,0,0, 0.5)" }}
                    className="fixed top-0 right-0 bottom-0 left-0 h-full w-full"
                  ></div>
                )}
                {openCategory ? (
                  <div
                    className="nav__category__menu"
                    style={{ left: "0%", top: 0 }}
                  >
                    <div
                      onClick={() => setOpenCategory(false)}
                      className="flex items-center cursor-pointer gap-x-2 pb-4 bg-white"
                    >
                      <IoMdClose />
                      Закрыть окно
                    </div>
                    {navCategoryList.map((category, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          // dispatch(getSubCategoriesAll(category.id));
                          navigate(`/category/${category.id}`);
                          // setSubCategoryState(true);
                          setOpenCategory(false);
                        }}
                        className="flex items-center category__li"
                      >
                        <img
                          className="mr-3"
                          src={URL + category.photo}
                          alt=""
                        />
                        {category.name}
                      </li>
                    ))}
                    <ul
                      className={`sub__category ${subCategoryState && "open"}`}
                    >
                      <div
                        onClick={() => {
                          dispatch(subCategoryFilter());
                          setSubCategoryState(false);
                        }}
                        className="flex items-center cursor-pointer gap-x-2 px-6 py-4 bg-white"
                      >
                        <MdOutlineArrowBackIosNew />
                        Назад
                      </div>
                      {subCategory?.map((sub, idx) => (
                        <li className="relative" key={idx}>
                          <Link
                            className="flex items-center"
                            to={`/filter/` + sub.id}
                            onClick={() => {
                              setOpenCategory(false);
                              dispatch(subCategoryFilter(sub.id));
                            }}
                          >
                            <img
                              className="mr-3"
                              src={`${URL}${sub.photo}`}
                              alt=""
                            />
                            {sub.name}
                          </Link>
                          {/* <ul className="sub__sub-category">
                                {sub.childs?.map((subSub, idx) => (
                                  <li key={idx}>
                                    <Link
                                      to={`/filter/` + subSub.id}
                                      onClick={() =>
                                        dispatch(subCategoryFilter(subSub.id))
                                      }
                                    >
                                      <img
                                        src={`${URL}/${subSub.photo}`}
                                        alt=""
                                      />
                                      {subSub.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div
                    className="nav__category__menu"
                    style={{ left: "-100%", top: 0 }}
                  ></div>
                )}
                <div className={`nav__item__search ml-8`}>
                  <div className="relative flex search__input">
                    <input
                      type="search"
                      onChange={(e) => {
                        let newFilter = {
                          ...filterProduct,
                          query: e.target.value,
                        };
                        setFilterProduct(newFilter);
                        dispatch(getProductsByFilter(newFilter));
                      }}
                      onKeyPress={
                        (e: KeyboardEvent<HTMLDivElement>) =>
                          e.key == "Enter" &&
                          navigate(`/search/product/${e.target.value}`)
                        // (navigate("/search/product"),
                        // dispatch(getShopsByFilter(filterProduct)))
                      }
                      className={`${
                        filterSearch == "1" ? "block" : "hidden"
                      } po-product py-2 w-full text-sm rounded-md pl-4 pr-8 text-gray-900`}
                      placeholder="Я ищу..."
                    />
                    <input
                      type="search"
                      onChange={(e) => {
                        let newFilter = {
                          ...filterShop,
                          query: e.target.value,
                        };
                        setFilterShop(newFilter);
                        dispatch(getShopsByFilter(newFilter));
                      }}
                      onKeyPress={(e: KeyboardEvent<HTMLDivElement>) =>
                        e.key == "Enter" && navigate("/search/shop")
                      }
                      className={`${
                        filterSearch == "2" ? "block" : "hidden"
                      } po-user py-2 w-full text-sm text-white rounded-md pl-4 pr-8 text-gray-900`}
                      placeholder="Я ищу..."
                    />
                    <span
                      onClick={() => {
                        navigate("/products-by-photo");
                        setOpenMenuSearch(false);
                      }}
                      className="flex items-center justify-center pr-2"
                    >
                      <AiOutlineCamera
                        fill="#717171"
                        className="hover:fill-slate-700 cursor-pointer"
                        size={26}
                      />
                    </span>
                    <span
                      onClick={() => {
                        navigate(
                          `${
                            filterSearch === "1"
                              ? `/search/product/${filterProduct.query}`
                              : "/search/shop "
                          }`
                        );
                        setOpenMenuSearch(false);
                      }}
                      className="flex items-center justify-center pr-2"
                    >
                      <IoIosSearch
                        fill="#717171"
                        className="hover:fill-slate-700 cursor-pointer"
                        size={26}
                      />
                    </span>
                    <div className="search__category">
                      {productFelter.map((product, idx) => (
                        <Link
                          key={idx}
                          to={`/add/${product.id}`}
                          onClick={() => setOpenMenuSearch(false)}
                          className="search__text"
                        >
                          <img src={URL + product.photo} alt="" />
                          {product.name}
                          <span>{product.price}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="nav__item__icons ml-6">
                  <MLink
                    onClick={() => {
                      if (!reduxToken) {
                        setShowProfileModal(true);
                      }
                    }}
                    to={`${reduxToken ? "/compare" : ""}`}
                  >
                    <MNav
                      className={`nav__router__link ${splitLocation[1]} === "" ? "active" : ""`}
                    >
                      <BiGitCompare fill="#7CBA4B" size={28} />
                      <span>
                        {language === "ru" && "Сравнить"}
                        {language === "uz" && "Solishtirma"}
                        {language === "en" && "Compare"}
                      </span>
                    </MNav>
                  </MLink>
                  <MLink
                    to={`${reduxToken ? "/selected" : ""}`}
                    onClick={() => {
                      if (!reduxToken) {
                        setShowProfileModal(true);
                      }
                    }}
                  >
                    <MNav
                      className={`nav__router__link ${splitLocation[1]} === "star" ? "active" : ""`}
                    >
                      <Badge
                        badgeContent={favoriteList?.length}
                        className="!mt-0"
                        color="error"
                      >
                        {shop_favoriteList?.length > 0 ? (
                          <Badge
                            style={{
                              marginTop: "0",
                              padding: "0 !important",
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            badgeContent={<BiUserCheck fill="#fff" size={14} />}
                          ></Badge>
                        ) : null}
                        <BsSuitHeartFill fill="#7CBA4B" size={28} />
                      </Badge>
                      <span>
                        {language === "ru" && "Избранные"}
                        {language === "uz" && "Sevimlilar"}
                        {language === "en" && "Favorites"}
                      </span>
                    </MNav>
                  </MLink>
                  <MLink
                    onClick={() => {
                      dispatch(getCart());
                      if (!reduxToken) {
                        setShowProfileModal(true);
                      }
                    }}
                    to={`${reduxToken ? "/basket" : ""}`}
                  >
                    <MNav
                      className={`nav__router__link ${splitLocation[1]} === "product" ? "active" : ""`}
                    >
                      <Badge
                        badgeContent={cartList?.length}
                        color="error"
                        className="!mt-0"
                      >
                        <img
                          src={basketIcon}
                          style={{ height: "28px", width: "28px" }}
                          alt=""
                        />
                        {/* <ImCart fill="#7CBA4B" size={28} /> */}
                      </Badge>
                      <span>
                        {language === "ru" && "Корзина"}
                        {language === "uz" && "Savatcha"}
                        {language === "en" && "Basket"}
                      </span>
                    </MNav>
                  </MLink>

                  <MLink
                    to={`${reduxToken ? "/cabinet" : ""}`}
                    onClick={() => {
                      if (!reduxToken) {
                        setShowProfileModal(true);
                      }
                    }}
                    className="infopage_link relative"
                  >
                    <MNav
                      className={`nav__router__link ${
                        splitLocation[1] === "profile" ? "active" : ""
                      } nav__item__icon__rigth`}
                    >
                      <FaUserAlt fill="#7CBA4B" size={28} />
                      <span>{user?.name || "Войти"}</span>
                    </MNav>
                    {reduxToken && (
                      <div className="openProfile">
                        <div className="profile__dropdown">
                          <Link to="/cabinet" className="profile__header">
                            <div
                              className="profile__image"
                              style={{ width: "25px" }}
                            >
                              {user?.photo ? (
                                <img
                                  src={`${URL}${user?.photo}`}
                                  alt=""
                                  className=""
                                  style={{
                                    borderRadius: "50%",
                                    minWidth: "25px",
                                    height: "25px",
                                  }}
                                />
                              ) : (
                                <img
                                  src="http://admin.chinni-buyum.uz//assets_files/images/user.png"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="profile__title">
                              {user?.name || "Нету данных"}
                            </div>
                          </Link>
                          <div className="profile__bottom">
                            <ul className="profile__ul">
                              <li className="profile__item">
                                <Link to="/deliverycart">
                                  <AiOutlineRight />
                                  Мои заказы
                                </Link>
                              </li>
                              <li className="profile__item">
                                <Link to="/payments">
                                  <AiOutlineRight />
                                  Мои платежи
                                </Link>
                              </li>
                              <li className="profile__item">
                                <Link to="/message">
                                  <AiOutlineRight />
                                  Мои сообщения
                                </Link>
                              </li>
                              <li className="profile__item">
                                <Link to="/selected">
                                  <AiOutlineRight />
                                  Избранные товара
                                </Link>
                              </li>
                              {reduxToken ? (
                                <li
                                  className="profile__item"
                                  onClick={handleLogout}
                                >
                                  <AiOutlineRight />
                                  Выйти
                                </li>
                              ) : (
                                <li className="profile__item">
                                  <Link to="/firstregister">
                                    <AiOutlineRight />
                                    Войти
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </MLink>
                </div>
                <div className="navbar__menu__inner">
                  <IconButton
                    style={{ padding: "0" }}
                    onClick={() => openProfileMenuFun(true)}
                  >
                    <FaRegUser className="menu__inner__icons" size={28} />
                  </IconButton>
                  <Link to="/basket">
                    <AiOutlineShopping
                      className="menu__inner__icons"
                      size={34}
                    />
                  </Link>
                  <IconButton
                    onClick={() => setOpenMenuSearch(true)}
                    style={{ padding: "0" }}
                  >
                    <FiSearch className="menu__inner__icons" size={32} />
                  </IconButton>
                  <IconButton
                    style={{ padding: "0" }}
                    onClick={() => setOpenCategory(true)}
                  >
                    <FiMenu className="menu__inner__icons" size={36} />
                  </IconButton>
                </div>
              </div>
            </MContainer>
          </MNavbar>
        </div>
      </div>
      {notification ? (
        <div
          onClick={handleNotification}
          className="w-full h-full fixed top-0 bottom-0"
          style={{ zIndex: "999" }}
        ></div>
      ) : null}
      {uopenMenuMobile ? (
        <div
          className={`uopenMenuMobile ${uopenMenuMobile ? "open" : ""}`}
          style={{ opacity: "1", visibility: "inherit", top: "0px" }}
        >
          <IconButton
            style={{ padding: "0" }}
            className="uopenMenuMobile-close-icon absolute"
            onClick={() => openMenuMobile(false)}
          >
            <IoIosClose fill="#fff" size={32} />
          </IconButton>
          <ul>
            {navCategoryList.map((category, index) => (
              <li>
                <Link
                  to={`/filter/${category.id}`}
                  onClick={() => setUopenMenuMobile(false)}
                  key={index}
                >
                  <img src={`${URL}/${category.photo}`} className="mr-3" />
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className={`uopenMenuMobile`}
          style={{ opacity: "0", visibility: "hidden", top: "-30px" }}
        ></div>
      )}
      {openMenuSearch ? (
        <div
          className={`search-menu-mobile ${
            openMenuSearch ? "search-open" : ""
          }`}
          style={{ opacity: "1", visibility: "inherit", top: "0px" }}
        >
          <IconButton
            style={{ padding: "0" }}
            className="search-menu-mobile-close-icon absolute"
            onClick={() => setOpenMenuSearch(false)}
          >
            <IoIosClose fill="#fff" size={32} />
          </IconButton>
          <div className={`nav__item__search ml-8`}>
            <div className="relative flex search__input">
              <input
                type="search"
                onChange={(e) => {
                  let newFilter = {
                    ...filterProduct,
                    query: e.target.value,
                  };
                  setFilterProduct(newFilter);
                  dispatch(getProductsByFilter(newFilter));
                }}
                onKeyPress={
                  (e: KeyboardEvent<HTMLDivElement>) =>
                    e.key == "Enter" &&
                    navigate(`/search/product/${e.target.value}`)
                  // (navigate("/search/product"),
                  // dispatch(getShopsByFilter(filterProduct)))
                }
                className={`${
                  filterSearch == "1" ? "block" : "hidden"
                } po-product py-2 w-full text-sm text-white rounded-md pl-4 pr-8 text-gray-900`}
                placeholder="Я ищу..."
              />
              <input
                type="search"
                onChange={(e) => {
                  let newFilter = {
                    ...filterShop,
                    query: e.target.value,
                  };
                  setFilterShop(newFilter);
                  dispatch(getShopsByFilter(newFilter));
                }}
                onKeyPress={(e: KeyboardEvent<HTMLDivElement>) =>
                  e.key == "Enter" && navigate(`/search/shop`)
                }
                className={`${
                  filterSearch == "2" ? "block" : "hidden"
                } po-user py-2 w-full text-sm text-white rounded-md pl-4 pr-8 text-gray-900`}
                placeholder="Я ищу..."
              />
              <span
                onClick={() => {
                  navigate("/products-by-photo");
                  setOpenMenuSearch(false);
                }}
                className="flex items-center justify-center pr-2"
              >
                <AiOutlineCamera
                  fill="#717171"
                  className="hover:fill-slate-700 cursor-pointer"
                  size={26}
                />
              </span>
              <span
                onClick={() => {
                  navigate(
                    `${
                      filterSearch === "1"
                        ? `/search/product/${filterProduct.query}`
                        : "/search/shop "
                    }`
                  );
                  setOpenMenuSearch(false);
                }}
                className="flex items-center justify-center pr-2"
              >
                <IoIosSearch
                  fill="#717171"
                  className="hover:fill-slate-700 cursor-pointer"
                  size={26}
                />
              </span>
              <div className="search__category">
                {productFelter.map((product, idx) => (
                  <Link
                    key={idx}
                    to={`/add/${product.id}`}
                    onClick={() => setOpenMenuSearch(false)}
                    className="search__text"
                  >
                    <img src={URL + product.photo} alt="" />
                    {product.name}
                    <span>{product.price}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`search-menu-mobile`}
          style={{ opacity: "0", visibility: "hidden", top: "-30px" }}
        ></div>
      )}
      {openProfileMenu ? (
        reduxToken ? (
          <div
            className={`openProfileMenu ${openProfileMenu ? "open" : ""}`}
            style={{ opacity: "1", visibility: "inherit", top: "0px" }}
          >
            <IconButton
              style={{ padding: "0" }}
              className="openProfileMenu-close-icon absolute"
              onClick={() => openProfileMenuFun(false)}
            >
              <IoIosClose fill="gray" size={32} />
            </IconButton>
            <ul>
              <li onClick={() => openProfileMenuFun(false)}>
                <Link to="/deliverycart">Мои заказы</Link>
              </li>
              <li onClick={() => openProfileMenuFun(false)}>
                <Link to="/payments">Мои платежи</Link>
              </li>
              <li onClick={() => openProfileMenuFun(false)}>
                <Link to="/message">Мои сообщения</Link>
              </li>
              <li onClick={() => openProfileMenuFun(false)}>
                <Link to="/selected">Избранные товара</Link>
              </li>
              <li
                style={{
                  color: "rgba(113, 113, 113, 0.7)",
                  padding: "8px 22px",
                }}
                onClick={handleLogout}
              >
                Выйти
              </li>
              <div className="flex items-center gap-x-6">
                <div
                  className="flex items-center gap-x-2"
                  onClick={() => {
                    window.localStorage.setItem(
                      "missLighting-Content-language",
                      "uz"
                    );
                    window.location.reload();
                  }}
                >
                  <img
                    style={{ minWidth: "22px", width: "22px" }}
                    value="uz"
                    src={UzFlag}
                    alt=""
                  />
                  uz
                </div>
                <div
                  className="flex items-center gap-x-2"
                  onClick={() => {
                    window.localStorage.setItem(
                      "missLighting-Content-language",
                      "ru"
                    );
                    window.location.reload();
                  }}
                >
                  <img
                    style={{ minWidth: "22px", width: "22px" }}
                    value="ru"
                    src={RuFlag}
                    alt=""
                  />
                  ru
                </div>
                <div
                  className="flex items-center gap-x-2"
                  onClick={() => {
                    window.localStorage.setItem(
                      "missLighting-Content-language",
                      "en"
                    );
                    window.location.reload();
                  }}
                >
                  <img
                    style={{ minWidth: "22px", width: "22px" }}
                    value="en"
                    src={EnFlag}
                    alt=""
                  />
                  en
                </div>
              </div>
            </ul>
          </div>
        ) : (
          <div
            className={`openProfileMenu2 ${openProfileMenu ? "open" : ""}`}
            onClick={() => setOpenProfileMenu(false)}
            style={{ opacity: "1", visibility: "inherit", top: "0px" }}
          >
            <IconButton
              style={{ padding: "0" }}
              className="openProfileMenu-close-icon absolute"
              onClick={() => openProfileMenuFun(false)}
            >
              <IoIosClose fill="gray" size={32} />
            </IconButton>
            <ul className="bg-white py-10" style={{ width: "95%" }}>
              <div className="flex flex-col items-center justify-center gap-4">
                <Button
                  variant="outlined"
                  className="!w-full"
                  color="primary"
                  onClick={() => {
                    navigate("/firstregister");
                  }}
                >
                  Зарегистрироваться
                </Button>
                <Button
                  variant="contained"
                  className="!w-full"
                  color="primary"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Войти
                </Button>
              </div>
            </ul>
          </div>
        )
      ) : (
        <div
          className={`openProfileMenu`}
          style={{ opacity: "0", visibility: "hidden", top: "-30px" }}
        ></div>
      )}
    </>
  );
};

export default Navbar;
