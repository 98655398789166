import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { MContainer, MLink, ShowAllLink } from "../../element/Elemens";
import "../../assets/scss/_product.scss";
import NavbarMenu from "../../container/NavbarMenu";
import { Cart } from "../../component/Cart/Cart";
import Title from "../../component/Title/Title";
import parse from "html-react-parser";
import delivery from "../../assets/images/11 122001.png";
import image25 from "../../assets/images/image 25.png";
import image26 from "../../assets/images/image 26.png";
import image27 from "../../assets/images/image 27.png";
import approved111 from "../../assets/images/approved111 .png";
import Group222 from "../../assets/images/Group222.png";
import creditCard333 from "../../assets/images/credit-card333.png";
import group1 from "../../assets/images/boxes-free-icon-font.png";
import group2 from "../../assets/images/buyurtma.png";
import group3 from "../../assets/images/dostavka.png";
import group4 from "../../assets/images/online-tolov.png";
import group5 from "../../assets/images/aloqa.png";
import brbalo from "../../assets/images/3d-rendering-loft-luxury-living-room-with-shelf-near-dining-table 2.png";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
  History,
  Autoplay,
  Virtual,
  EffectCube,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsAll,
  getSliders,
  getBrands,
  getProductsSortPopular,
  getProductSortNew,
  getBanners,
  getPopularProducts,
} from "../../redux/actions/productActions";
import AddedRecentlyProducts from "./AddedRecentlyProducts";
import DiscountProducts from "./DiscountProducts";
import MainPageNewsBox from "./MainPageNewsBox";
import PreLoader from "../../component/PreLoader/PreLoader";
import { getShop, getShopList } from "../../redux/actions/shopActions";
import { Link, useParams } from "react-router-dom";
import ProductsViewed from "../../component/ProductsViewed/ProductsViewed";
import { Button, Skeleton } from "@mui/material";
import { getReklama } from "../../redux/actions/reklamaActions";
import reklama1 from "../../assets/images/approved (1) 1 (2).png";
import reklama2 from "../../assets/images/rek2.png";
import reklama3 from "../../assets/images/rek3.png";
import front from "../../assets/images/front-view-of-delivery-man-with-a-package 1.png";
import cPhoto from "../../assets/images/-406649227467550670 22.png";
import axios from "axios";
import newYearAksiyaImg from "../../assets/images/new-year-aksiya.jpg";
import megaSaleImg from "../../assets/images/mega-sale.jpg";
import NewsBox from "../../component/NewsBox/NewsBox";
import { MdOutlineDateRange } from "react-icons/md";
import { getNews } from "../../redux/actions/newsActions";
import { getCurrency } from "../../redux/actions/userActions";
import ErrorModal from "../../component/ErrorModal/ErrorModal";
import { getPopularCategory } from "../../redux/actions/categoryActions";
import ImageGallery from "react-image-gallery";
import ProductSkelet from "../../component/loading/ProductSkelet";
const tokenLocal = window.localStorage.getItem("missLightingToken");
const language = window.localStorage.getItem("missLighting-Content-language");

const API_URL = "http://admin.chinni-buyum.uz";

const Product = () => {
  const dispatch = useDispatch();
  const [popularProductsParams, setPopularProductsParams] = useState({
    page: 1,
    "per-page": 5,
  });
  const popularProductsPageChange = (e, value) => {
    let newParams = {
      ...popularProductsParams,
      "per-page": (popularProductsParams["per-page"] += 5),
    };
    dispatch(getPopularProducts(newParams));
  };
  const [newListProductsParams, setnewListProductsParams] = useState({
    page: 1,
    "per-page": 5,
  });
  const newListProductsPageChange = (e, value) => {
    let newParams = {
      ...newListProductsParams,
      "per-page": (newListProductsParams["per-page"] += 5),
    };
    dispatch(getProductSortNew(newParams));
  };
  const [productsListProductsParams, setproductsListProductsParams] = useState({
    page: 1,
    "per-page": 5,
  });
  const productsListProductsPageChange = (e, value) => {
    let newParams = {
      ...productsListProductsParams,
      "per-page": (productsListProductsParams["per-page"] += 5),
    };
    dispatch(getProductsAll(newParams));
  };

  useEffect(() => {
    dispatch(getProductsAll(productsListProductsParams));
    dispatch(getProductSortNew(newListProductsParams));
    dispatch(getPopularProducts(popularProductsParams));
    dispatch(getSliders());
    dispatch(getBanners());
    // dispatch(getShop());
    dispatch(getShopList({ page: 1, "per-page": 6 }));
    dispatch(getReklama());
    dispatch(getPopularCategory());
    dispatch(getBrands());
    dispatch(getNews({ page: 1, "per-page": 6 }));
    dispatch(getCurrency());
  }, []);

  const [productList2, setProductList2] = useState(5);
  const [productList3, setProductList3] = useState(3);
  const popularProducts = useSelector((state) => state.product.popularProducts);
  const { popularProductsLoading } = useSelector((state) => state.product);

  const { loading } = useSelector((state) => state.product);
  const { loading2 } = useSelector((state) => state.shop);

  const productsList = useSelector((state) => state.product.productsList);
  const { productsListProductsLoading } = useSelector((state) => state.product);
  const newProductsList = useSelector((state) => state.product.newList);
  const { newListProductsLoading } = useSelector((state) => state.product);
  const sliders = useSelector((state) => state.product.sliders);
  const { slidersLoading } = useSelector((state) => state.product);
  const gallery = [];
  const galleryImage = async () => {
    await sliders?.forEach((item) => {
      gallery.push({
        original: `http://admin.chinni-buyum.uz${item?.photo}`,
      });
    });
  };
  galleryImage();
  const banners = useSelector((state) => state.product.banners);
  const { bannersLoading } = useSelector((state) => state.product);
  const get_brands = useSelector((state) => state.product.brands_main);
  const get_ShopList = useSelector((state) => state.shop.shopList);
  const navCategoryList = useSelector((state) => state.category.list);
  const popularCategory = useSelector(
    (state) => state.category.popularCategory
  );
  const { popularCategoryLoading } = useSelector((state) => state.category);
  const cartList = useSelector((state) => state.cart.list);
  const reklames = useSelector((state) => state.reklama.list);
  const news = useSelector((state) => state.news.news);

  return (
    <>
      <NavbarMenu />
      {/* {loading && <PreLoader />} */}
      <MContainer>
        {/* <ImageGallery
          items={gallery}
          autoPlay={true}
          showPlayButton={false}
          showBullets={false}
          showFullscreenButton={false}
          additionalClass="mySwiper2 main-banner-swiper-slide mt-8"
        /> */}
        {slidersLoading ? (
          <Skeleton className="!transform-none" height={450} />
        ) : (
          <Swiper
            modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
            spaceBetween={30}
            pagination={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
            }}
            navigation={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={2000}
            // lazy={true}
            style={{ zIndex: "0" }}
            className="mySwiper2"
          >
            {sliders?.map((slide, idx) => (
              <SwiperSlide
                key={idx}
                className="main-banner-swiper-slide relative"
              >
                <a href={slide.link}>
                  <img
                    src={API_URL + slide.photo}
                    className="swiper-lazy rounded-2xl"
                    alt=""
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </MContainer>
      <MContainer>
        {/* <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          slidesPerView={1}
          navigation
          pagination
          // lazy={true}
          loop
        >
          {sliders?.map((slider, index) => (
            <SwiperSlide
              className="main-banner-swiper-slide"
              key={index}
              style={{}}
            >
              <img
                src={API_URL + slider?.photo}
                // src={API_URL + slider?.photo}
                // srcSet={API_URL + slider?.photo}
                alt=""
                className="swiper-lazy"
              />
            </SwiperSlide>
          ))}
        </Swiper> */}
        {bannersLoading ? (
          <Skeleton className="!transform-none !mt-8" height={225} />
        ) : (
          <div
            className="flex items-center justify-between lg:px-10 px-2 lg:py-24 py-16 mt-8 bg-center bg-cover h-40-media"
            style={{
              backgroundImage: `url("${API_URL + banners[0]?.photo}")`,
              borderRadius: "15px",
            }}
          >
            <div className="xl:text-4xl lg:text-3xl md:text-2xl font-bold text-white">
              {banners[0]?.description}
            </div>
          </div>
        )}
      </MContainer>
      {popularCategory?.length > 0 && (
        <MContainer className="md:py-12 py-8">
          <Title
            nameUz="Ommabop kategoriyalar"
            name="Популярные категории"
            nameEn="Popular categories"
          />
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            pagination={true}
            autoplay={{
              delay: 2000,
            }}
            breakpoints={{
              1024: {
                slidesPerView: 5,
              },
              767: {
                slidesPerView: 3,
              },
              0: {
                slidesPerView: 2,
              },
            }}
            navigation
            className="mySwiper4 mt-8"
          >
            {popularCategory?.map((product, idx) => (
              <SwiperSlide
                key={idx}
                className="main-banner-swiper-slide5"
                style={{}}
              >
                <Link to={`/filter/${product.id}`}>
                  <img
                    className="popular-category-img"
                    src={`${API_URL}${product?.photo}`}
                    alt=""
                  />
                  <div className="text-center">{product?.name}</div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          {loading && <PreLoader />}
        </MContainer>
      )}
      <MContainer className="md:py-12 py-8">
        <Title
          nameUz="Ommabop tovarlar"
          name="Популярные товары"
          nameEn="Popular goods"
        />
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
          {popularProducts?.map((product, index) => (
            <Cart product={product} key={index} />
          ))}
          {popularProductsLoading && <ProductSkelet length={5} />}
        </div>
        <button onClick={popularProductsPageChange} className="show__all">
          {language === "ru" && "Показать еще"}
          {language === "uz" && "Ko'proq ko'rish"}
          {language === "en" && "Show more"}
        </button>
      </MContainer>
      {bannersLoading ? (
        <Skeleton className="!transform-none !mt-8" height={350} />
      ) : (
        <MContainer className="md:py-0 py-8">
          <img
            src={API_URL + banners[1]?.photo}
            className="rounded-2xl w-full object-contain"
            style={{ maxHeight: "500px" }}
            alt=""
          />
        </MContainer>
      )}
      <MContainer className="md:py-16 py-8">
        <div className="flex items-center flex-wrap justify-between mb-4">
          <Title
            nameUz="Sizning do'konlaringiz"
            nameEn="Popular markets"
            name="Популярные магазины"
            m="!mb-0"
          />
          <Link className="show-market" to={"/search/shop"}>
            Посмотреть все
          </Link>
        </div>
        {loading2 && <PreLoader />}
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
            767: {
              slidesPerView: 2,
            },
          }}
          navigation
          className="mySwiper4 mt-8"
        >
          {!get_ShopList ? (
            <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-6">
              <ProductSkelet length={3} />
            </div>
          ) : (
            get_ShopList?.map((item, idx) => (
              <SwiperSlide
                key={idx}
                className="main-banner-swiper-slide4"
                style={{}}
              >
                <div>
                  <div className="brand__box !shadow-lg">
                    <div className="brand__text flex p-6">
                      <img src={API_URL + item.photo} alt="" />
                    </div>
                    <div className="flex flex-col p-6 gap-y-10 border-t">
                      <div className="flex items-center justify-between">
                        <Link
                          to={`/selleradres/${item.id}`}
                          className="brand__name-name px-2 py-1 font-bold"
                        >
                          {item.name}
                        </Link>
                        <Link
                          style={{ color: "#7CBA4B", borderColor: "#7CBA4B" }}
                          className="text-sm mt-auto rounded-full w-max ml-auto border font-bold py-3 px-5 duration-200 hover:bg-slate-50"
                          to={`/selleradres/${item.id}`}
                        >
                          Посмотреть
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </MContainer>
      <DiscountProducts />
      {bannersLoading ? (
        <Skeleton className="!transform-none !mt-8" height={350} />
      ) : (
        <MContainer className="md:py-0 py-8">
          <img
            src={API_URL + banners[2]?.photo}
            className="rounded-2xl w-full object-contain"
            style={{ maxHeight: "600px" }}
            alt=""
          />
        </MContainer>
      )}
      <MContainer className="md:py-12 py-8">
        <Title
          nameUz="Yangi tovarlar"
          name="Новые товары"
          nameEn="New products"
        />
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
          {newProductsList?.map((product, index) => (
            <Cart key={index} product={product} />
          ))}
          {newListProductsLoading && <ProductSkelet length={5} />}
        </div>
        <button onClick={newListProductsPageChange} className="show__all">
          {language === "ru" && "Показать еще"}
          {language === "uz" && "Ko'proq ko'rish"}
          {language === "en" && "Show more"}
        </button>
      </MContainer>
      <MContainer className="md:py-0 py-8">
        <Title nameUz="Buyurtma bo'yicha" name="Под заказ" nameEn="On order" />
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
          {productsList?.map((product, index) => (
            <Cart key={index} product={product} />
          ))}
          {productsListProductsLoading && <ProductSkelet length={5} />}
        </div>
        <button onClick={productsListProductsPageChange} className="show__all">
          {language === "ru" && "Показать еще"}
          {language === "uz" && "Ko'proq ko'rish"}
          {language === "en" && "Show more"}
        </button>
      </MContainer>
      {/* <div className="delivery my-8 px-4 pt-8 xl:px-12 md:px-4">
        <div className="delivery__text">
          <div className="text__title">
            {language === "ru" && "Доставка"}
            {language === "uz" && "Yetkazib berish"}
            {language === "en" && "Delivery"}
          </div>
          <p>
            {language === "ru" && "ПО ВСЕМ УЗБЕКИСТАНУ"}
            {language === "uz" && "BUTUN O'ZBEKISTON BO'YICHA"}
            {language === "en" && "ALL OVER UZBEKISTAN"}
          </p>
        </div>
        <img src={delivery} alt="not found" />
      </div> */}
      {/* <AddedRecentlyProducts /> */}
      {/* <MainPageNewsBox /> */}
      <MContainer className="md:py-12 py-8">
        <Title nameUz="Yangiliklar" nameEn="News" name="Новости" />
        <div className="news__boxes__products grid sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-7">
          {news?.map((item, idx) => (
            <div className="news-box__box" key={idx}>
              <Link to={`/mnews/${item.id}`}>
                <img
                  src={API_URL + item.photo}
                  alt="not found"
                  style={{ flexShrink: "0" }}
                />
              </Link>
              <div className="box__text">
                <div className="box__title">{item.name}</div>
                <div className="mb-4" style={{ color: "#757575" }}>
                  {parse(item.description_mini)}
                </div>
                <div className="n__date">
                  <MLink
                    className="duration-200 hover:bg-slate-50"
                    to={`/mnews/${item.id}`}
                  >
                    {language === "ru" && "Подробно"}
                    {language === "uz" && "Batafsil"}
                    {language === "en" && "Detail"}
                  </MLink>
                  <span className="flex items-center">
                    <MdOutlineDateRange
                      className="!mr-2"
                      fill="#999"
                      size={19}
                    />{" "}
                    24.04.2021
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <button className="show__all mt-8">
          {language === "ru" && "Показать еще"}
          {language === "uz" && "Ko'proq ko'rish"}
          {language === "en" && "Show more"}
        </button> */}
      </MContainer>
      {/* <MContainer className="md:py-16 py-8">
        <Title
          nameUz="Yaqinda ko'rganlaringiz"
          name="Вы недавно смотрели"
          nameEn="Recently viewed"
        />
        <ProductsViewed />
        {loading && <PreLoader />}
      </MContainer> */}
      <MContainer className="md:py-12 py-8">
        <Title
          name="Наши преимущества"
          nameUz="Bizning afzalliklarimiz"
          nameEn="Our advantages"
        />
        <div className="adventage">
          <div className="adventage__box">
            <img style={{ width: "60px" }} src={reklama1} alt="not found" />
            <div className="adventage__title">
              {language === "ru" && "Закупки у производителей"}
              {language === "uz" && "Ishlab chiqaruvchilardan xaridlar"}
              {language === "en" && "Purchases from manufacturers"}
            </div>
            <div className="text">
              {language === "ru" &&
                "Через наш сайт вы можете связаться с производителями, Вы можете получить подробную информацию о продукте"}
              {language === "uz" &&
                "Saytimiz orqali siz ishlab chiqaruvchilar bilan bog'lanishingiz, mahsulot haqida batafsil ma'lumotga ega bo'lishingiz mumkin"}
              {language === "en" &&
                "Through our site, you can contact manufacturers, you can get detailed information about the product"}
            </div>
          </div>
          <div className="adventage__box">
            <img style={{ width: "60px" }} src={reklama1} alt="not found" />
            <div className="adventage__title">
              {language === "uz" && "Tanlang va buyurtma bering"}
              {language === "en" && "Choose and order"}
              {language === "ru" && "Выбирайте и заказывайте"}
            </div>
            <div className="text">
              {language === "uz" &&
                "Mahsulot sizga yoqdimi? Kerakli parametrlarni belgilagan holda buyurtmani onlayn rasmiylashtirsangiz bo'ladi."}
              {language === "ru" &&
                "Вам понравился товар? Вы можете сделать заказ онлайн, указав необходимые параметры."}
              {language === "en" &&
                "Did you like the product? You can make an order online by specifying the necessary parameters."}
            </div>
          </div>
          <div className="adventage__box">
            <img style={{ width: "60px" }} src={reklama1} alt="not found" />
            <div className="adventage__title">
              {language === "ru" && "Быстрая доставка"}
              {language === "uz" && "Tezkor yetkazib berish"}
              {language === "en" && "Fast delivery"}
            </div>
            <div className="text">
              {language === "uz" &&
                "Mahsulotlarni butun dunyo bo'ylab kichik va katta hajmlarda yetkazib beramiz"}
              {language === "ru" &&
                "Товары малых и больших размеров по всему миру мы доставим"}
              {language === "en" &&
                "Products in small and large sizes all over the world we will deliver"}
            </div>
          </div>
          <div className="adventage__box">
            <img style={{ width: "60px" }} src={reklama1} alt="not found" />
            <div className="adventage__title">
              {language === "uz" && "Barcha turdagi to'lovlar"}
              {language === "ru" && "Все виды платежей"}
              {language === "en" && "All types of payments"}
            </div>
            <div className="text">
              {language === "uz" &&
                "Naqd, onlayn , pul ko'chirish yo'li bilan to'lovlarni amalga oshiring"}
              {language === "ru" &&
                "Оплата наличными, онлайн, денежным переводом увеличивать"}
              {language === "en" &&
                "Make payments by cash, online, money transfer increase"}
            </div>
          </div>
          <div className="adventage__box">
            <img style={{ width: "60px" }} src={reklama1} alt="not found" />
            <div className="adventage__title">
              {language === "uz" && "Biz aloqadamiz 24/7"}
              {language === "ru" && "Мы на связи 24/7"}
              {language === "en" && "We are in touch 24/7"}
            </div>
            <div className="text">
              {language === "uz" &&
                "Tovarlar va xaridlaringiz bo'yicha savollar bo'lsa yordam berishga tayyormiz!"}
              {language === "ru" &&
                "Помощь с вопросами о продуктах и ​​ваших покупках мы готовы!"}
              {language === "en" &&
                "Help with questions about products and your purchases we are ready!"}
            </div>
          </div>
        </div>
      </MContainer>
      {/* <MContainer className="">
        <Title nameUz="Brendlar" name="Бренды" nameEn="Brands" />
      </MContainer>
      <div className="md:py-12 py-8">
        <Swiper
          modules={[Navigation]}
          // modules={[Navigation, Autoplay, History, Virtual, Lazy, A11y]}
          spaceBetween={10}
          slidesPerView={7}
          breakpoints={{
            0: {
              slidesPerView: 3,
            },
            769: {
              slidesPerView: 5,
            },
            1440: {
              slidesPerView: 7,
            },
          }}
          navigation
          loop
          className="brands swiper-brands"
        >
          {get_brands?.map((brand, index) => (
            <SwiperSlide
              style={{
                height: "80px",
              }}
              key={index}
            >
              <img
                style={{ height: "100%", objectFit: "contain" }}
                // src={API_URL + brand?.photo}
                src={API_URL + brand?.photo}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
      {/* <MContainer className="md:py-12 py-8">
        <Title name="SEO текст" />
        <div className="seo__text">
          {language === "ru" && (
            <>
              Маркетплейс «Chinni-buyum.uz» предлагает недорого люстры для дома,
              квартиры, офиса, общественный помещений. В нашем каталоге вы
              можете сделать выбор из тысяч моделей ведущих мировых брендов.
              Понятный интерфейс сайта и мобильного приложения, качественные
              фото сделают покупку простой и быстрой, а доставка по Ташкенту, и
              по всему Узбекистану – еще и удобной!
              <Title m="mt-6" name="Ассортимент без границ" />
              Каталог нашего маркетплейса включает:
              <ul className="list-disc pl-12 my-8">
                <li>
                  люстры потолочные – идеальное решение для невысоких потолков;
                </li>
                <li>
                  подвесные – эффектные осветительные приборы, которые украсят
                  любое помещение;
                </li>
                <li>
                  любые стили люстр - от роскошных хрустальных до строгих
                  минималистичных.
                </li>
              </ul>
              Здесь представлена продукция самых разных брендов из Европы, Азии
              и России: Немаловажно, что ассортимент «Chinni-buyum.uz» готов
              предложить модели люстр, цена которых подойдет для любого бюджета.
              <Title m="mt-6" name="Выбирать люстру просто" />
              Чтобы максимально облегчить покупку и сделать выбор простым и
              быстрым, мы подключили множество фильтров. Укажите параметры
              поиска, и система автоматически подберет модель. Карточки товаров
              содержат качественные детальные фото и подробные описания
              характеристик. В результате, подобрать и купить люстру,
              максимально подходящую вашим требованиям можно буквально за
              считанные минуты! Обращайте внимание на габариты прибора и его
              мощность, чтобы он гармонично вписался в помещение дома или офиса,
              а его яркости было достаточно. На дизайн, который должен
              соответствовать стилю интерьера. А также на типы используемых
              ламп.
              <Title m="mt-6" name="Доставка – быстро и недорого" />
              Расположение наших офисов, складов и шоу-румов - Ташкенту. Поэтому
              каждый клиент может заказать доставку на адрес в этих городах, и
              мы осуществим ее всего за один день. Для покупателей из других
              регионов предусмотрена возможность доставки услугами транспортных
              компаний.
              <div className="font-bold inline-block">
                Среди других преимуществ покупки в
              </div>
              <span className="ml-2">«Chinni-buyum.uz»</span>
              <ul className="list-disc pl-12 my-8">
                <li>расширенная гарантия 12 месяцев;</li>
                <li>собственная монтажная служба;</li>
                <li>бесплатные профессиональные консультации;</li>
                <li>товары проверенного качества по доступной цене.</li>
              </ul>
              Кроме того, купить люстру у нас можно, воспользовавшись любой
              формой оплаты. Выбирайте!
            </>
          )}
        </div>
      </MContainer> */}
    </>
  );
};

export default Product;
