import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import top from "../../assets/images/topCategory.png";
import { Link } from "react-router-dom";
import { subCategoryFilter } from "../../redux/actions/categoryActions";
import Title from "../../component/Title/Title";
import NavbarMenu from "../../container/NavbarMenu";
const API = "http://admin.chinni-buyum.uz";

const TopCategory = () => {
  const dispatch = useDispatch();
  const navCategoryList = useSelector((state) => state.category.categoryList);

  return (
    <>
      <NavbarMenu />
      <div className="shadow-2xl xl:p-12 py-6">
        <Title
          name="Топ категории"
          nameUz="Yuqori kategoriyalar"
          nameEn="Top categories"
        />
        <Container>
          <div className="grid md:grid-cols-2 gap-4">
            <img src={top} alt="" />
            <ul
              style={{
                background: "#131e3d",
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {navCategoryList?.map((item) => (
                <li
                  key={item.id}
                  onClick={() => dispatch(subCategoryFilter(item.id))}
                >
                  <Link
                    className="flex items-center hover:bg-orange-700 duration-200 text-white p-3"
                    to={`/filter/` + item.id}
                  >
                    <img
                      className="mr-3"
                      height="35"
                      width="35"
                      src={API + item.photo}
                      alt=""
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TopCategory;
