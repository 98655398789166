import React, { useEffect, useState } from "react";
import { Cart } from "../../component/Cart/Cart";
import { getProductsAll } from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { MContainer, MLink, ShowAllLink } from "../../element/Elemens";
import Title from "../../component/Title/Title";
import PreLoader from "../../component/PreLoader/PreLoader";
const language = window.localStorage.getItem("missLighting-Content-language");

export default function AddedRecentlyProducts() {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    sort: "recently",
  });
  const [slice, setSlice] = useState(5);

  useEffect(() => {
    dispatch(getProductsAll(filter));
  }, []);

  const { loading } = useSelector((state) => state.product);

  const productsList = useSelector((state) => state.product.list);

  return (
    <MContainer className="md:py-12 py-8">
      <Title
        nameUz="Yaqinda qo'shilgan tovarlar"
        name="Недавно добавленные товары"
        nameEn="Recently added products"
      />
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
        {productsList?.slice(0, slice).map((product, index) => (
          <Cart key={index} product={product} />
        ))}
      </div>
      {loading && <PreLoader />}
      {slice !== productsList?.length && (
        <button
          onClick={() => setSlice(productsList?.length)}
          className="show__all"
        >
          {language === "ru" && "Показать еще"}
          {language === "uz" && "Ko'proq ko'rish"}
          {language === "en" && "Show more"}
        </button>
      )}
    </MContainer>
  );
}
