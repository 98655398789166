import React from "react";
import Logo from "../../assets/images/POWERED BY MISS LIGHTING.svg";
import Warning from "../../assets/images/warning.svg";
import { removeAccount } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 w-screen h-screen p-8 z-[9999] bg-white">
      <img src={Logo} alt="" className="mb-8" />
      <div className="bg-[#fff5f5] rounded-[8px] p-[1rem]">
        <div className="flex gap-4">
          <img src={Warning} className="h-max w-[24px]" alt="" />
          <div className="flex flex-col gap-[0.75rem] [&>*]:text-[14px]">
            <div className="text-[#FA5252]">Внимание</div>
            <div>Удаление учетной записи Chinni buyum означает прощание с:</div>
            <ul className="list-disc pl-[40px]">
              <li>История заказов: Ваша история заказов будет удалена</li>
              <li>
                Награды и баллы: накопили кучу вкусных наград? Пуф! Они исчезнут
                вместе с вашей учетной записью.
              </li>
            </ul>
            <div>
              Держаться секунду! Вы можете удалить свою учетную запись только
              один раз в месяц. Это всего лишь мера безопасности для обеспечения
              безопасности вашей учетной записи.
            </div>
            <div>
              Все еще хотите удалить? Мы понимаем. Если вы уверены, продолжайте.
              Но если у вас есть какие-либо вопросы или вы хотите поговорить о
              состоянии вашего аккаунта, наша дружная команда поддержки Chinni buyum всегда рада помочь!
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          dispatch(removeAccount());
          navigate("/");
        }}
        className="text-[#FA5252] text-[14px] rounded-[4px] mt-8 font-bold p-[12px] border border-[#FA5252]"
      >
        Удалить аккаунт
      </button>
    </div>
  );
};

export default Logout;
