import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cart } from "../../component/Cart/Cart";
import NavbarMenu from "../../container/NavbarMenu";
import { MContainer } from "../../element/Elemens";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import { createShop } from "../../redux/actions/shopActions";
import { MdFavoriteBorder } from "react-icons/md";
import Title from "../../component/Title/Title";
const API_URL = "http://admin.chinni-buyum.uz";

const SearchShop = () => {
  const dispatch = useDispatch();
  const shopFilter = useSelector((state) => state.filter.shopList);

  return (
    <>
      <NavbarMenu />
      <MContainer>
        <Title name="Магазины" nameUz="Do'konlar" nameEn="Markets" />
        <div className="grid grid-cols-3 mb-12">
          {shopFilter?.map((item, idx) => (
            <div key={idx} className="brand__box !shadow-lg">
              <div className="brand__text flex p-6">
                <img src={API_URL + item.photo} alt="" />
              </div>
              <div className="flex flex-col p-6 gap-y-10 border-t">
                <div className="flex items-center justify-between">
                  <Link
                    to={`/selleradres/${item.id}`}
                    className="brand__name-name px-2 py-1 font-bold"
                  >
                    {item.name}
                  </Link>
                  <Link
                    style={{ color: "#7CBA4B", borderColor: "#7CBA4B" }}
                    className="text-sm mt-auto rounded-full w-max ml-auto border font-bold py-3 px-5 duration-200 hover:bg-slate-50"
                    to={`/selleradres/${item.id}`}
                  >
                    Посмотреть
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </MContainer>
    </>
  );
};

export default SearchShop;
