import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MContainer } from "../../element/Elemens";
import "../../assets/scss/_add.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ProgressBarLine } from "react-progressbar-line";
import { MdFavoriteBorder, MdFavorite, MdViewInAr } from "react-icons/md";
import Title from "../../component/Title/Title";
import { Navigation, Pagination, Scrollbar, A11y, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../../assets/scss/_modal-delivery.scss";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import {
  getProductOne,
  getUnit,
  relatedProducts,
} from "../../redux/actions/productActions";
import {
  createComment,
  getCart,
  getCommentFilterWithDate,
  getCommentFilterWithRate,
  getComments,
  postCartAdd,
} from "../../redux/actions/cartActions";
import { Cart } from "../../component/Cart/Cart";
import Shop from "../../component/Shop/Shop";
import { addCompare, compareList } from "../../redux/actions/compareActions";
import { createFavorite } from "../../redux/actions/favoriteActions";
import ProductsViewed from "../../component/ProductsViewed/ProductsViewed";
import { getRegions, getRegionsSub } from "../../redux/actions/categoryActions";
import parse from "html-react-parser";
import { IconButton } from "@mui/material";
import PreLoader from "../../component/PreLoader/PreLoader";
import { getLogistSort } from "../../redux/actions/filterActions";
import { BsChevronDown } from "react-icons/bs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  AiFillCheckCircle,
  AiOutlineHeart,
  AiTwotoneHeart,
} from "react-icons/ai";
import Button from "@mui/material/Button";
import { AiOutlineMessage } from "react-icons/ai";
import NavbarMenu from "../../container/NavbarMenu";
import ErrorModal from "../../component/ErrorModal/ErrorModal";
import { chatCreate } from "../../redux/actions/userActions";

const API = `${process.env.REACT_APP_API_DOMAIN}`;
const language = window.localStorage.getItem("missLighting-Content-language");

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  height: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Add = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [review, setReview] = useState("");
  const [rate, setRate] = useState("");

  useEffect(() => {
    dispatch(getProductOne(id));
    dispatch(relatedProducts(id));
    dispatch(compareList(id));
    dispatch(getRegions());
    dispatch(getComments(id));
    dispatch(getUnit());
  }, [id]);

  const currency = window.localStorage.getItem("missLighting-Content-currency");

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [showModal, setShowModal] = useState(false);
  const [sortComment, setSortComment] = useState();

  const productOne = useSelector((state) => state.product.data);
  const { loading3 } = useSelector((state) => state.cart);
  const [favorite, setFavorite] = useState(productOne?.shop?.isFavorite);
  const comments = useSelector((state) => state.cart.commentList);
  const related_products = useSelector(
    (state) => state.product.related_products
  );
  const desc = String(productOne.description);
  const comp = String(productOne.composition);

  const sizes = productOne.filters?.filter((obj) => {
    return obj.type === null;
  });

  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(productOne.rating);
  const ratingAsync = async () => {
    await productOne.rating;
    if (productOne.rating !== null && productOne.rating !== undefined) {
      setRating(productOne.rating);
    }
  };

  const ratingChanged = (newRating) => {
    setRate(newRating);
  };
  const [slice, setSlice] = useState(5);
  const handleSlice = (e) => {
    setSlice(e);
  };
  const [detailCount, setDetailCount] = useState(1);
  const [reviewsSlice, setReviewsSlice] = useState(3);
  const handleReviewSlice = (e) => {
    setReviewsSlice(e);
  };
  const minusCounter = (value) => {
    if (detailCount <= 1) {
    } else {
      setDetailCount(value - 1);
    }
  };
  const plusCounter = (value) => {
    setDetailCount(value + 1);
  };
  const colorPhoto = productOne.products?.find((item, index) => index === 0);
  let [sizesArr, setSizesArr] = useState([]);
  const bgColorName = productOne?.color?.color;
  let gallery = [];

  const galleryImage = async () => {
    await productOne?.gallery?.forEach((item) => {
      gallery.push({
        original: `http://admin.chinni-buyum.uz${item}`,
        thumbnail: `http://admin.chinni-buyum.uz${item}`,
      });
    });
  };
  galleryImage();
  const productColors = [];
  const getProductColor = async () => {
    await productOne?.productColors?.forEach((item) => {
      productColors.push(item);
    });
  };
  getProductColor();
  const { option } = useSelector((state) => state.product);
  // colorImage();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const { reduxToken } = useSelector((state) => state.user);
  const [favoriteAdd, setFavoriteAdd] = useState(false);
  const favoriteList = useSelector((state) => state.favorite.favoritiesList);
  const favoritesId = favoriteList?.map((item) => item.id);

  useEffect(() => {
    if (favoritesId?.includes(+productOne.id)) {
      setFavoriteAdd(true);
    } else {
      setFavoriteAdd(false);
    }
  }, [favoritesId, favoriteList]);

  return (
    <>
      <ErrorModal
        showModal={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        text="Зарегистрируйте чтобы написать"
      />
      <div className="add-product pb-12" key={id}>
        {/* {loading && (
          <PreLoader
            absolute="absolute"
            background="#fff"
            top="0"
            right="0"
            bottom="0"
            left="0"
            height="-webkit-fill-available"
            margin="auto"
          />
        )} */}
        <NavbarMenu />
        <MContainer className="!mb-4 c8">
          <Link to="/">Главная страница / </Link>
          <Link to={`/products-by-category/${productOne.category?.id}`}>
            {productOne?.category ? productOne?.category.name : ""}
          </Link>
        </MContainer>
        <MContainer>
          <div>
            <div
              className="cart-justify flex lg:flex-nowrap flex-wrap"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h1
                  style={{
                    color: "#434343",
                    fontSize: "40px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  {productOne?.name}
                </h1>
                <div
                  className="flex items-center lg:flex-nowrap flex-wrap"
                  style={{
                    gap: "20px",
                  }}
                >
                  <div className="stars">
                    <ReactStars
                      edit={false}
                      count={5}
                      size={24}
                      activeColor="#7AB94A"
                      value={rating}
                      onChange={ratingAsync()}
                    />
                  </div>
                  <div className="comment">
                    <div className="c8">
                      {productOne?.reviews?.length} отзывов
                    </div>
                  </div>
                  {productOne?.shop && (
                    <div
                      className="flex items-center ml-auto hover:underline cursor-pointer"
                      // to={`/message/type=shop/${productOne?.user?.id}`}
                      onClick={() => {
                        if (!reduxToken) {
                          setShowErrorModal(true);
                        } else {
                          if (
                            window.confirm(
                              "Вы хотите, чтобы ваше первое слова было об этом товаре?"
                            )
                          ) {
                            dispatch(
                              chatCreate({
                                getter_id: productOne?.shop?.id,
                                message: `Я хочу написать о товаре №${
                                  productOne?.id
                                }, имя: ${productOne?.name}, линк: ${(
                                  <Link to={window.location.href}>
                                    {window.location.href}
                                  </Link>
                                )}`,
                                type_user: "shop",
                              })
                            );
                            navigate(
                              `/message/type=shop/${productOne?.shop?.id}`
                            );
                          }
                        }
                      }}
                      style={{ color: "#7AB94A" }}
                    >
                      <AiOutlineMessage
                        className="mr-1"
                        fill="#7AB94A"
                        size={22}
                      />{" "}
                      Написать в магазин
                    </div>
                  )}
                </div>
              </div>
              {favoriteAdd === true ? (
                <AiTwotoneHeart
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(createFavorite({ product_id: productOne?.id }))
                  }
                  fill="#7AB94A"
                  size={32}
                />
              ) : (
                <AiOutlineHeart
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(createFavorite({ product_id: productOne?.id }))
                  }
                  size={32}
                />
              )}
            </div>
          </div>
        </MContainer>
        <MContainer>
          <div
            className="product-list"
            style={{
              background: "#f8f8fa",
              padding: "30px 30px 80px 30px",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <div
              className="product__img relative mr-8"
              style={{
                disply: "flex",
              }}
            >
              <div
                className="relative"
                style={{ width: "-webkit-fill-available" }}
              >
                {gallery?.length > 0 && (
                  <IconButton
                    className="gallery-btn-btn !absolute"
                    style={{
                      zIndex: "2",
                      background: "rgb(255, 149, 0)",
                      bottom: "-60px",
                      right: "80px",
                    }}
                  >
                    <MdViewInAr
                      onClick={() => setOpenModal(true)}
                      style={{ zIndex: "2" }}
                      size={32}
                    />
                  </IconButton>
                )}
                <div
                  onClick={() => setOpenModal(false)}
                  className={`${
                    openModal ? "fixed" : "hidden"
                  } top-0 right-0 bottom-0 left-0`}
                  style={{ background: "rgba(0,0,0,0.5)", zIndex: "99999" }}
                ></div>
                <div
                  onClick={() => setOpenModal(true)}
                  className={`${
                    openModal ? "block" : "hidden"
                  } fixed h-3/5 w-3/5 mx-auto top-0 left-0 right-0 bg-white`}
                  style={{ transform: "translate(0%,50%)", zIndex: "999999" }}
                >
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Lazy]}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation
                    loop
                    pagination={{ clickable: true }}
                    lazy={true}
                    style={{ height: "-webkit-fill-available" }}
                  >
                    {gallery.map((slider, index) => (
                      <SwiperSlide key={index} style={{ height: "80%" }}>
                        <img
                          data-src={`${slider.original}`}
                          alt=""
                          style={{ height: "-webkit-fill-available" }}
                          className="swiper-lazy !object-contain"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                {gallery.length > 0 && (
                  <ImageGallery
                    items={gallery}
                    thumbnailPosition="left"
                    showPlayButton={true}
                    showBullets={false}
                    showFullscreenButton={true}
                  />
                )}
              </div>
              {gallery.length < 1 && (
                <img
                  src="http://admin.chinni-buyum.uz//assets_files/images/no-photo.png"
                  alt=""
                />
              )}
              {/* <slide-mobile className="mobile__slide" /> */}
              {/* <div className="loader__blur">
								<div className="loader__into">
									<div>
										<Loader />
									</div>
								</div>
							</div> */}
            </div>
            <div className="product__information">
              <div className="product__about">
                <div className="product__price">
                  {currency === "Сум" &&
                    productOne?.price?.toLocaleString("de-De") + " " + "сум"}
                  {currency === "У.е." &&
                    productOne?.price_usd?.toLocaleString("de-De") +
                      " " +
                      "У.е."}
                  <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                    {productOne.price_old &&
                      productOne.price_old + productOne?.currency?.name}
                  </span>
                </div>
                {/* <div className="min__max-price">
                  <p>
                    {productOne.count_price1
                      ? "от " + productOne.count_price1
                      : null}
                    {productOne.count_price2
                      ? " до " + productOne.count_price2
                      : null}
                    {productOne.unit?.name} - {productOne.price_opt_small}{" "}
                    {productOne?.currency?.name}
                  </p>
                  <p>
                    {productOne.count_price2
                      ? "от " + productOne.count_price2
                      : null}
                    {productOne.unit?.name} -{" "}
                    {productOne.price_opt && productOne.price_opt}{" "}
                    {productOne?.currency?.name}
                  </p>
                </div> */}
              </div>
              {/* <div className="color__title">
                <span className="mr-2">
                  {productOne?.filters?.map((e, idx) =>
                    e.type === "input" ? e.name + " " + e.value : ""
                  )}
                  // productOne.filters?.type
                </span>
                {productOne.filters?.value}
              </div> */}
              {/* <div className="font-bold mb-4">
                Артикул: <span className="c8"> 2134543</span>
              </div> */}
              {productOne?.shop?.name && (
                <div className="font-bold mb-4">
                  Магазин:{" "}
                  <Link
                    to={`/selleradres/${productOne?.shop?.id}`}
                    className="duration-200 hover:!bg-amber-500 ml-2 text-sm rounded-full border px-3 py-2 text-white"
                    style={{ background: "#7AB94A", borderColor: "#7AB94A" }}
                  >
                    {productOne?.shop?.name}
                  </Link>
                </div>
              )}
              {productOne.products?.length > 0 && (
                <div className="font-bold">Цвет:</div>
              )}
              <div className="color__boxes mt-2">
                {productColors?.length > 0 &&
                  productColors?.map((item, idx) => (
                    <div
                      key={idx}
                      style={{ border: "1px solid #7AB94A", color: "#7AB94A" }}
                      className="border rounded-full px-3 py-2 text-sm mt-2 mr-2"
                    >
                      {item.name}
                    </div>
                  ))}
                {productOne?.products?.map((color, idx) => (
                  <Link
                    key={idx}
                    to={`/add/${color.id}`}
                    className="border rounded-full px-3 py-2 text-sm mt-2 mr-2"
                  >
                    {/* <img src={API + color.photo} alt="" /> */}
                    {color?.color}
                  </Link>
                ))}
                {/* <Link to={`/add/${colorPhoto?.id}`} className="color__box bg-white mt-2 mr-2">
                  {colorPhoto?.photo && (
                    <img src={API + colorPhoto?.photo} alt="" />
                  )}
                  <div className="text-sm text-center mt-1">
                    {colorPhoto?.color || productOne?.color?.name}
                  </div>
                </Link> */}
              </div>
              {/* <div className="font-bold mt-4">
                Бранд: <span className="c8">chiro</span>
              </div> */}
              {/* {sizes?.length !== 0 && (
                <div className="product__size-title">Размер</div>
              )}
              <div className="product__size">
                {sizes &&
                  sizes.map((size, idx) => (
                    <div
                      key={idx}
                      onChange={(e) => {
                        let newFilter = {
                          ...sizesArr,
                          [`filter_value_id[${0}]`]: Number(e.target.value),
                        };
                        setSizesArr(newFilter);
                      }}
                      className="radioContainer"
                    >
                      <input
                        defaultChecked={false}
                        value={size.value_id}
                        type="radio"
                        name="radio"
                        id={idx + "productRazmer"}
                      />
                      <label htmlFor={idx + "productRazmer"} className="circle">
                        {size.value}
                      </label>
                    </div>
                  ))}
              </div> */}
              {/* <div className="flex flex-col mt-2">
                {productOne?.brand?.photo && (
                  <>
                    <div className="product__size-title mb-2">Бренд</div>
                    <img
                      style={{
                        minWidth: "50px",
                        width: "50px",
                        marginRight: "1rem",
                      }}
                      src={`${API}${productOne?.brand?.photo}`}
                      alt="not found"
                    />
                  </>
                )}
              </div> */}
              <div className="mt-4 mb-4">
                {/* <Counter
                  product_id={productOne?.id}
                  count_product={detailCount}
                  onClickIncrement={(product_id) => {
                    dispatch(postCartMinus({ product_id }));
                  }}
                  onClickDecrement={(product_id_count) => {
                    dispatch(
                      postCartAdd({
                        amount: detailCount,
                        product_id: product_id_count,
                      })
                    );
                  }}
                /> */}
                <div className="product__order">
                  <button
                    className="product__increment"
                    onClick={() => {
                      minusCounter(detailCount);
                    }}
                  >
                    -
                  </button>
                  <p>{detailCount}</p>
                  <button
                    className="product__decrement"
                    onClick={() => {
                      plusCounter(detailCount);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <p
                className="flex items-center cursor-pointer my-4"
                onClick={handleOpen2}
              >
                <div className="flex items-center">
                  Информация о доставке{" "}
                  <BsChevronDown className="ml-2" size={22} />
                </div>
              </p>
              <div>
                <Modal
                  keepMounted
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style} className="add-modal">
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Информация о логистической компании
                    </Typography>
                    <Typography
                      id="keep-mounted-modal-description"
                      sx={{ mt: 2 }}
                    >
                      Логистическая компания - это предприятие, оказывающее
                      услуги по транспортировке, обработке и хранению грузов,
                      содействуя своим клиентам в процессе продвижения товаров
                      от производителя к потребителю. Логистика, как наука,
                      впервые появилась в армии – в интендантской службе.
                      Поскольку конкурентная борьба в современном бизнесе по
                      остроте своей сродни военным действиям, достижения этой
                      науки быстро были восприняты коммерсантами.
                    </Typography>
                  </Box>
                </Modal>
              </div>
              {/* <ModalAddress /> */}
              <div className="product__buttons mt-4">
                {/* <Link to="" id="buy">
                  Купить
                </Link> */}
                <button
                  type="submit"
                  id="korzina__button"
                  style={{ color: "white", padding: "8px 22px" }}
                  onClick={() => {
                    dispatch(
                      postCartAdd({
                        ...sizesArr,
                        product_id: productOne?.id,
                        amount: detailCount,
                      })
                    );
                    dispatch(getCart());
                  }}
                >
                  В корзину
                </button>
                <button
                  onClick={() => {
                    dispatch(addCompare({ product_id: productOne?.id }));
                    navigate("/compare");
                  }}
                  id="delete"
                >
                  Сравнить
                </button>
              </div>
            </div>
          </div>
        </MContainer>
        <MContainer>
          <div className="recommended">
            <div className="r__box">
              <Tabs className="mini__page">
                <TabList>
                  <Tab>Характеристики</Tab>
                  <Tab>Описание</Tab>
                  <Tab>Отзывы</Tab>
                </TabList>
                <TabPanel>
                  <div className="r__type">
                    {productOne.productProperties?.length > 0 &&
                      productOne.productProperties?.map((item, idx) => (
                        <div
                          key={idx}
                          className="lg:w-2/4 w-full flex items-center lg:flex-nowrap flex-wrap justify-between"
                        >
                          <div>{item.key_name}</div>
                          <div>{item.value_name}</div>
                        </div>
                      ))}
                    {productOne.filters?.length > 0 &&
                      productOne.filters?.map((item, idx) => (
                        <>
                          <div
                            key={idx}
                            className={`lg:w-2/4 w-full flex items-center lg:flex-nowrap flex-wrap justify-between`}
                          >
                            <div>{item.name}</div>
                            <div>{item.value}</div>
                          </div>
                        </>
                      ))}
                    {/* {productOne?.width && (
                      <div className="lg:w-2/4 w-full flex items-center lg:flex-nowrap flex-wrap justify-between">
                        <div>
                          {language === "en" && "Width: "}
                          {language === "ru" && "Ширина: "}
                          {language === "uz" && "Kengligi: "}
                        </div>
                        <div>{productOne?.width}</div>
                      </div>
                    )} */}
                    {/* {productOne?.weight && (
                      <div className="lg:w-2/4 w-full flex items-center lg:flex-nowrap flex-wrap justify-between">
                        <div>
                          {language === "en" && "Weight: "}
                          {language === "ru" && "Масса: "}
                          {language === "uz" && "Vazni: "}
                        </div>
                        <div>{productOne?.weight}</div>
                      </div>
                    )} */}
                    {/* {productOne?.height && (
                      <div className="lg:w-2/4 w-full flex items-center lg:flex-nowrap flex-wrap justify-between">
                        <div>
                          {language === "en" && "Height: "}
                          {language === "ru" && "Высота: "}
                          {language === "uz" && "Balandligi: "}
                        </div>
                        <div>{productOne?.height}</div>
                      </div>
                    )} */}
                  </div>
                  {/* <div className="sub__character">{option && option}</div> */}
                </TabPanel>
                <TabPanel>
                  <div className="r__type">
                    {parse(desc !== "null" ? desc : "")}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="p-customer mt-8">
                    <Title name="Отзывы" nameUz="Sharhlar" nameEn="Comments" />
                    <span className="c8">Сортировать по: </span>
                    <span
                      onClick={() => (
                        dispatch(getCommentFilterWithRate(id)),
                        setSortComment(true)
                      )}
                      className={`${
                        sortComment === true && "border-b-2 border-gray-500"
                      } cursor-pointer c8`}
                    >
                      Оценке
                    </span>
                    <span
                      onClick={() => (
                        dispatch(getCommentFilterWithDate(id)),
                        setSortComment(false)
                      )}
                      className={`${
                        sortComment === false && "border-b-2 border-gray-500"
                      } cursor-pointer c8`}
                    >
                      Дате
                    </span>
                    <div
                      className="customer flex flex-wrap justify-between pt-4"
                      key={id}
                    >
                      <div className="c__box" key={id}>
                        <div className="c__box-title font-bold">
                          Отзывы покупателей{" "}
                          <span className="font-bold">
                            ({productOne?.reviews_count})
                          </span>
                        </div>
                        {loading3 && <PreLoader />}
                        {!loading3 &&
                          comments
                            ?.slice(0, reviewsSlice)
                            .map((review, idx) => (
                              <div key={idx} className="user__comment">
                                <div className="user flex items-center">
                                  <div className="user__name mr-3">
                                    {review?.user?.name}
                                  </div>
                                  <div className="stars">
                                    <ReactStars
                                      value={review?.rate}
                                      edit={false}
                                      isHalf={true}
                                      color="#7AB94A"
                                    />
                                  </div>
                                </div>
                                <p className="my-2 c8">{review?.review}</p>
                                <div className="date__sale">
                                  <div className="date c8">{review?.date}</div>
                                </div>
                              </div>
                            ))}
                        {reviewsSlice < comments?.length &&
                          comments?.length > 3 && (
                            <button
                              onClick={() =>
                                handleReviewSlice(comments?.length)
                              }
                              type="submit"
                              className="comment__btn"
                            >
                              Показать больше комментариев
                            </button>
                          )}
                      </div>
                      {/* <div
                        className="c__box bg"
                        style={{ background: "#7CBA4B" }}
                      >
                        <div className="box__title">У вас есть отзыв</div>
                        <ReactStars
                          onChange={ratingChanged}
                          value={0}
                          count={5}
                          size={24}
                          activeColor="#7AB94A"
                        />
                        <textarea
                          onChange={(e) => {
                            setReview(e.target.value);
                          }}
                          placeholder="Ваш отзыв"
                        ></textarea>
                        <button
                          onClick={() => {
                            dispatch(
                              createComment({
                                review: review,
                                rate: rate,
                                product_id: productOne?.id,
                              })
                            );
                            dispatch(getComments(id));
                          }}
                        >
                          Отправить вопрос
                        </button>
                      </div> */}
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
            {/* <Shop
              isFavourite={() => setFavorite(productOne?.shop?.isFavorite)}
              userId={productOne?.user?.id}
              shop={productOne?.shop}
            /> */}
          </div>
        </MContainer>
        <MContainer className="c-products pb-8">
          <Title name="Похожие товары" />
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
            {related_products?.slice(0, slice).map((related, idx) => (
              <Cart key={idx} product={related} />
            ))}
          </div>
          <div className="advertising__button mt-8 text-center">
            {slice !== related_products?.length ||
              (slice > 5 && (
                <button
                  onClick={() => handleSlice(related_products?.length)}
                  className="show__button mb-4"
                >
                  Показать еще
                </button>
              ))}
          </div>
        </MContainer>
        {/* <MContainer>
          <div className="question__title">
            Отзывы и вопросы{" "}
          </div>
          <div className="question">
            <div className="q__box">
              <div className="comment">
                <div className="order">{productOne?.reviews_count}</div>
                <p className="pb-5">
                  На основании {productOne?.reviews_count} отзыва
                </p>
                <div className="stars">
                  <ReactStars />
                </div>
              </div>
              <div className="place">
                <div className="lines">
                  <div className="flex flex-row w-full">
                    <ProgressBarLine
                      value={productOne?.review_separate?.rate_5 * 100}
                      strokeWidth={1}
                    />
                    <div className="w-4">
                      <h6>5</h6>
                    </div>
                  </div>
                </div>
                <div className="lines">
                  <div className="flex flex-row w-full">
                    <ProgressBarLine
                      value={productOne?.review_separate?.rate_4 * 100}
                      strokeWidth={1}
                    />
                    <div className="w-4">
                      <h6>4</h6>
                    </div>
                  </div>
                </div>
                <div className="lines">
                  <div className="flex flex-row w-full">
                    <ProgressBarLine
                      value={productOne?.review_separate?.rate_3 * 100}
                      strokeWidth={1}
                    />
                    <div className="w-4">
                      <h6>3</h6>
                    </div>
                  </div>
                </div>
                <div className="lines">
                  <div className="flex flex-row w-full">
                    <ProgressBarLine
                      value={productOne?.review_separate?.rate_2 * 100}
                      strokeWidth={1}
                    />
                    <div className="w-4">
                      <h6>2</h6>
                    </div>
                  </div>
                </div>
                <div className="lines">
                  <div className="flex flex-row w-full">
                    <ProgressBarLine
                      value={productOne?.review_separate?.rate_1 * 100}
                      strokeWidth={1}
                    />
                    <div className="w-4">
                      <h6>1</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="q__box">
              <div className="box">
                <div className="protsent">
                  <Circle
                    progress={35}
                    textColor="#fff"
                    progressColor="#fff"
                    bgColor="rgba(16, 0, 0, 0.16)"
                    roundedStroke={true}
                  />
                  <DonutChart />
                  <Progress />
                  </div>
                <p>Barcha sotuvlar</p>
              </div>
              <div className="box">
                <Circle
                  progress={35}
                  textColor="#fff"
                  progressColor="#fff"
                  bgColor="rgba(16, 0, 0, 0.16)"
                  roundedStroke={true}
                />
                <Progress />
                <p>Barcha sotuvlar</p>
              </div>
              <div className="box">
                <Circle
                  progress={35}
                  textColor="#fff"
                  progressColor="#fff"
                  bgColor="rgba(16, 0, 0, 0.16)"
                  roundedStroke={true}
                />
                <Progress />
                <p>Barcha sotuvlar</p>
              </div>
            </div>
          </div>
        </MContainer> */}
        {/* <MContainer className="mb-12">
          <div className="recently-products">
            <Title name="Вы недавно смотрели" />
            <ProductsViewed />
          </div>
        </MContainer> */}
      </div>
      {/* <AddPageDelivery
        regions={regions}
        regions_sub={regions_sub}
        amount={detailCount}
        showModal={showModal}
        onCloseModal={() => {
          setShowModal(false);
        }}
        onClickRegionId={(data) => {
          dispatch(getRegionsSub(data));
        }}
      /> */}
    </>
  );
};

export default Add;
