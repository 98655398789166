import React from "react";
import { Container } from "@mui/system";
import NavbarMenu from "../../container/NavbarMenu";
import Title from "../../component/Title/Title";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
const API = "http://admin.chinni-buyum.uz/";

const Exhibitions = () => {
  return (
    <>
      <NavbarMenu />
      <div className="shadow-2xl xl:p-12 px-2 py-6">
        <Title name="Выставки" nameUz="Ko'rgazmalar" nameEn="Exhibitions" />
        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-4 exbitions">
          <Card>
            <CardMedia
              component="img"
              image="https://idsb.tmgrup.com.tr/ly/uploads/images/2020/03/23/thumbs/800x531/26574.jpg?v=1584966017"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                18.05.2004
              </Button>
            </CardActions>
          </Card>
          <Card>
            <CardMedia
              component="img"
              image="https://www.zurich.com/en/about-us/sponsorship/-/media/CDE4DF22CE1947939315839BC68FF1D9.ashx"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                18.05.2004
              </Button>
            </CardActions>
          </Card>
          <Card>
            <CardMedia
              component="img"
              image="https://www.zurich.com/en/about-us/sponsorship/-/media/7AFC65FA4B6546409EDABC51E031FF95.ashx"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                18.05.2004
              </Button>
            </CardActions>
          </Card>
          <Card>
            <CardMedia
              component="img"
              image="https://www.nationalgallery.sg/sites/default/files/20211117_JO2_8461_MIDRES.jpg"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                18.05.2004
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Exhibitions;
