import { Button, Pagination } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Cart } from "../../component/Cart/Cart";
import PreLoader from "../../component/PreLoader/PreLoader";
import NavbarMenu from "../../container/NavbarMenu";
import { MContainer } from "../../element/Elemens";
import { productsByPhoto } from "../../redux/actions/filterActions";
import { BiSearch } from "react-icons/bi";
import ErrorModal from "../../component/ErrorModal/ErrorModal";
import Compressor from "compressorjs";
import ProductSkelet from "../../component/loading/ProductSkelet";

const ProductsByPhoto = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [compressedFile, setCompressedFile] = useState();
  const [params, setParams] = useState({
    photo: compressedFile,
    page: 1,
    "per-page": 2,
  });

  useEffect(() => {
    let newParams = {
      ...params,
      photo: compressedFile,
    };
    setParams(newParams);
    if (compressedFile) {
      dispatch(productsByPhoto(newParams));
    } else {
      dispatch(productsByPhoto());
    }
  }, [compressedFile]);

  const onPageChange = (event, value) => {
    let newParams = {
      ...params,
      page: value,
    };
    setParams(newParams);
    dispatch(productsByPhoto(newParams));
  };

  const productsPhotoList = useSelector(
    (state) => state.filter.productsByPhoto
  );
  const { productsByPhotoPagination } = useSelector((state) => state.filter);
  const { loading } = useSelector((state) => state.filter);

  const onImageChange = (file, element) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", function (e) {
        element.src = e.target.result;
      });
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.8,
        success: (compressedResult) => {
          setCompressedFile(compressedResult);
          let newParams = {
            ...params,
            photo: compressedResult,
          };
          setParams(newParams);
        },
      });
    }
  };

  const { reduxToken } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);

  return (
    <>
      <NavbarMenu />
      <ErrorModal
        text="Зарегистрируйте чтобы искать"
        onClose={() => setShow(false)}
        showModal={show}
      />
      <MContainer>
        <div className="mt-4 mb-10 text-xl text-center">Поиск по картинки</div>
        <form className="flex items-center border-2 border-dashed duration-200 hover:border-solid hover:border-black hover:border-2 w-80 h-80 mx-auto relative cursor-pointer">
          <input
            onChange={(e) =>
              onImageChange(
                e.target.files[0],
                document.querySelectorAll("#avatarImage")[0]
              )
            }
            type="file"
            accept="image/*"
            className="absolute top-0 right-0 bottom-0 left-0 opacity-0 cursor-pointer"
          />
          {/* {reduxToken && ( */}
          {/* )} */}
          <img
            // onClick={() => {
            //   if (!reduxToken) {
            //     setShow(true);
            //   }
            // }}
            id="avatarImage"
            className="w-full h-full object-contain"
            src="https://keywordtool.io/images/svg/undraw_web_search_eetr.svg"
            alt=""
          />
        </form>
        {/* <div className="mx-auto w-80 flex items-center justify-center mt-12">
          <Button
            startIcon={<BiSearch color="#1976D2" fill="#1976D2" size={22} />}
            variant="outlined"
            disabled={!photo}
            onClick={() => {
              dispatch(productsByPhoto(params));
            }}
          >
            Искать
          </Button>
        </div> */}

        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 mb-12 mt-16">
          {productsPhotoList?.map((product, idx) => (
            <Cart key={idx} product={product} />
          ))}
          {loading && <ProductSkelet length={10} />}
        </div>
        {productsPhotoList?.length > 0 && !loading && (
          <div className="flex items-center justify-center my-8">
            <Pagination
              onChange={onPageChange}
              count={productsByPhotoPagination?.pageCount}
              defaultPage={productsByPhotoPagination?.currentPage}
              color="warning"
            />
          </div>
        )}
        {productsPhotoList?.length < 1 && (
          <div className="text-center text-xl mb-12">Ничего не найдено</div>
        )}
      </MContainer>
    </>
  );
};

export default ProductsByPhoto;
