import React from "react";
import { FooterLink } from "../../element/Elemens";
import "../../assets/scss/_footer.scss";
import { FaTelegramPlane, FaFacebookF, FaTiktok } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import maskGroup from "../../assets/images/1 12.png";
import a from "../../assets/images/a.png";
import bulut from "../../assets/images/bulut2.png";
import bulut2 from "../../assets/images/bulut3.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/POWERED BY MISS LIGHTING.svg";
import { Container } from "@mui/system";
import { Button, IconButton } from "@mui/material";
import { BsChevronUp } from "react-icons/bs";
import { useLayoutEffect } from "react";

const Footer = () => {
  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="footer__back">
      {/* <img className="footer__bulut1" src={bulut} alt="" />
      <img className="footer__bulut2" src={bulut2} alt="" />
      <img className="footer__bulut3" src={bulut2} alt="" />
      <img className="footer__bulut4" src={bulut2} alt="" /> */}
      <Container maxWidth="xl" className="!md:py-12 !py-8">
        <div className="container mx-auto px-md-8 px-4">
          <div className="py-12 grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2">
            <div className="footer__logo" style={{ zIndex: "1" }}>
              <FooterLink to="/">
                <img
                  style={{
                    height: "150px",
                    filter:
                      "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(6deg) brightness(104%) contrast(102%)",
                  }}
                  className="h-8 w-auto mx-auto"
                  src={Logo}
                  alt=""
                />
              </FooterLink>
            </div>
            <div style={{ zIndex: "1" }}>
              <div className="footer__title">
                <h5>Покупателям</h5>
              </div>
              <ul className="footer__ul">
                {/* <li className="footer__li">
                  <FooterLink to="">Как сделать заказ</FooterLink>
                </li> */}
                {/* <li className="footer__li">
                  <FooterLink to="/basket">Способы оплаты</FooterLink>
                </li> */}
                <li className="footer__li">
                  <FooterLink to="/deliveries">Доставка</FooterLink>
                </li>
                <li className="footer__li">
                  <FooterLink to="/returnproduct">Возврат товара</FooterLink>
                </li>
                <li className="footer__li">
                  <Link to="/politics">Политика и конфиденциальность</Link>
                </li>
                {/* <li className="footer__li">
                  <FooterLink to="/returnproduct">Правила продажи</FooterLink>
                </li> */}
                <li className="footer__li">
                  <FooterLink to="/questions">Вопросы и ответы</FooterLink>
                </li>
                <li className="footer__li">
                  <FooterLink to="contacts">О нас</FooterLink>
                </li>
              </ul>
            </div>
            <div style={{ zIndex: "1" }}>
              <div className="footer__title">
                <h5>Компания</h5>
              </div>
              <ul className="footer__ul">
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  Р/с: 20208000100978846001
                </li>
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  МФО: 00837
                </li>
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  НАЗВАНИЕ БАНКА: ТОШКЕНТ Ш, "ИПОТЕКА БАНК" АТИБ ЮНУСОБОД
                  ФИЛИАЛИ
                </li>
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  ИНН: 306015957
                </li>
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  ЮРИДИЧЕСКИЙ АДРЕС: TOSHKENT VILOYATI, QIBRAY TUMANI, TON-ARIQ
                  QFY, MEHNAT MAHALLASI, SOBIQ XARBIY QISM KO'CHASI
                </li>
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  ОКЭД: 27400
                </li>
                <li className="footer__li mb-2" style={{ fontSize: "13px" }}>
                  ПОЧТОВЫЙ ИНДЕКС: 111216 (не точно)
                </li>
                {/* <li className="footer__li">
                  <FooterLink to="/requisites">Реквизиты</FooterLink>
                </li> */}
                {/* <li className="footer__li">
                  <FooterLink to="/contacts">Контакты</FooterLink>
                </li> */}
                {/* <li className="footer__li">
                  <FooterLink to="#">FAQ</FooterLink>
                </li> */}
              </ul>
            </div>
            <div style={{ zIndex: "1" }}>
              <div className="footer__title">
                <h5>Мы в соцсетях</h5>
              </div>
              <div className="footer__soc flex items-center mt-4">
                <FooterLink to="">
                  <FaTelegramPlane
                    onMouseOut={({ target }) => (target.style.fill = "#fff")}
                    onMouseOver={({ target }) =>
                      (target.style.fill = "#7AB94A")
                    }
                    size={30}
                    fill="#fff"
                  />
                </FooterLink>
                <FooterLink to="" className="ml-3">
                  <BsInstagram
                    onMouseOut={({ target }) => (target.style.fill = "#fff")}
                    onMouseOver={({ target }) =>
                      (target.style.fill = "#7AB94A")
                    }
                    size={30}
                    fill="#fff"
                  />
                </FooterLink>
                <FooterLink to="" className="ml-3">
                  <FaFacebookF
                    onMouseOut={({ target }) => (target.style.fill = "#fff")}
                    onMouseOver={({ target }) =>
                      (target.style.fill = "#7AB94A")
                    }
                    size={30}
                    fill="#fff"
                  />
                </FooterLink>
              </div>
            </div>
            <div className="response__app" style={{ zIndex: "1" }}>
              <div className="footer__title">
                <h5>Мобильные устройства</h5>
              </div>
              <div className="footer__apps">
                <FooterLink to="">
                  <img src={a} alt="" />
                </FooterLink>
                <div className="flex items-center justify-between gap-y-4 flex-wrap mt-2">
                  <img
                    src="https://www.ccbank.bg/web/files/richeditor/fizicheski-lica/bankovi-karti/kartovi-uslugi/images.png"
                    alt=""
                    style={{ width: "45%" }}
                  />
                  <img
                    src="https://logos-download.com/wp-content/uploads/2022/01/Uzcard_Logo-700x367.png"
                    alt=""
                    style={{ width: "45%" }}
                  />
                </div>
                <div className="footer__up__icons">
                  <IconButton
                    onClick={handleScrollTop}
                    color="error"
                    style={{ background: "#fff" }}
                  >
                    <BsChevronUp color="#7AB94A" fill="#7AB94A" size={26} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* <Link to="/" className="footer__logo__text">
        POWERED BY
        <div>MISS LIGHTING</div>
      </Link> */}
    </div>
  );
};

export default Footer;
