import React, { useEffect } from "react";
import { MLink } from "../../element/Elemens";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import "../../assets/scss/_cart.scss";
import { useDispatch, useSelector } from "react-redux";
import { createFavorite } from "../../redux/actions/favoriteActions";
import { getCart, postCartAdd } from "../../redux/actions/cartActions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useState } from "react";
import ErrorModal from "../ErrorModal/ErrorModal";
const token = window.localStorage.getItem("missLightingToken");

export const Cart = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [showFavorite, setShowFavorite] = useState(false);
  const currency = window.localStorage.getItem("missLighting-Content-currency");
  const { oneCart } = useSelector((state) => state.favorite);
  const { toBuy } = useSelector((state) => state.cart);
  const { reduxToken } = useSelector((state) => state.user);
  const language = window.localStorage.getItem("missLighting-Content-language");
  const [favorite, setFavorite] = useState(false);
  const favoriteList = useSelector((state) => state.favorite.favoritiesList);
  const favoritesId = favoriteList?.map((item) => item.id);

  useEffect(() => {
    if (favoritesId?.includes(+product.id)) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  }, [favoritesId, favoriteList]);

  return (
    <>
      <ErrorModal
        text="Зарегистрируйте чтобы добавить в корзину"
        onClose={() => setShow(false)}
        showModal={show}
      />
      <ErrorModal
        text="Зарегистрируйте чтобы добавить в избранную"
        onClose={() => setShowFavorite(false)}
        showModal={showFavorite}
      />
      <div className="cart__box shadow-xl">
        <div className="cart__image overflow-hidden">
          <Link to={"/add/" + product?.id}>
            <img
              src={`http://admin.chinni-buyum.uz/${product?.photo}`}
              alt="not found"
            />
          </Link>
          {/* <Link to={"/add/" + product?.id} className="fast__review">
            Быстрый просмотр
          </Link> */}
          {product?.discount ? (
            <span className="skidka">{product?.discount} %</span>
          ) : null}
          {favorite ? (
            <Button className="heart">
              <AiTwotoneHeart
                size={28}
                onClick={() => {
                  if (!reduxToken) {
                    setShowFavorite(true);
                  } else {
                    dispatch(createFavorite({ product_id: product.id }));
                  }
                }}
              />
            </Button>
          ) : (
            <Button className="heart">
              <AiOutlineHeart
                size={28}
                onClick={() => {
                  if (!reduxToken) {
                    setShowFavorite(true);
                  } else {
                    dispatch(createFavorite({ product_id: product.id }));
                  }
                }}
              />
            </Button>
          )}
        </div>
        <div className="cart__info">
          <div className="flex justify-between mt-3">
            <Link
              to={`/filter/${product.category?.id}`}
              className="cart__cat duration-300 hover:underline"
            >
              {product?.category?.name}
            </Link>
            {/* <div className="cart__brand">{product.brand?.name}</div> */}
          </div>
          <div className="cart__title">
            <MLink to={`/add/${product?.id}`}>
              {language === "ru" && product?.name_ru}
              {language === "uz" && product?.name_uz}
              {language === "en" && product?.name_en}
            </MLink>
            <div className="cart__price">
              <p className="">
                {product?.price_old
                  ? product?.price_old + product?.currency?.name
                  : ""}
              </p>
              <h5>
                {currency === "Сум" && product?.price
                  ? product?.price?.toLocaleString("de-De") + " " + "Сум"
                  : undefined}
                {currency === "У.е." && product?.price_usd
                  ? product?.price_usd + " " + "У.е."
                  : undefined}
              </h5>
            </div>
          </div>
          <div className="cart__add">
            <button
              className="add text-sm rounded-full border font-bold py-3 px-5 duration-200 hover:bg-slate-50"
              onClick={() => {
                if (!reduxToken) {
                  setShow(true);
                } else {
                  dispatch(postCartAdd({ product_id: product.id, amount: 1 }));
                  dispatch(getCart());
                }
              }}
            >
              <span>В корзину</span>
            </button>
            <button
              onClick={() => {
                if (!reduxToken) {
                  setShow(true);
                } else {
                  dispatch(postCartAdd({ product_id: product.id, amount: 1 }));
                  dispatch(getCart());
                  navigate("/basket");
                }
              }}
              className="buy"
            >
              Купить
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
