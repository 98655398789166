const INITIAL_STATE = {
  data: {},
  octo_step: false,
  octoUrl: "",
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload, ...rest }) => {
  switch (type) {
    // order
    case "post_octo_start":
      return { ...state, loading: true, step: false, message: "" };
    case "post_octo_error":
      return { ...state, message: payload, loading: false };
    case "post_octo_success":
      return {
        ...state,
        loading: false,
        data: payload,
        octoUrl: payload.octo_pay_url,
        octo_step: true,
      };

    // profile
    case "logout":
      return { ...INITIAL_STATE };

    //sidebar toggle reducer
    case "sidebar_toggle":
      return { ...state, ...rest };

    /* other */
    default:
      return state;
  }
};
