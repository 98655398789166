import React from 'react';
import '../../assets/scss/_seller_adres.scss';
import { MContainer, ShowAllLink } from '../../element/Elemens';
import image5 from '../../assets/images/image 5.png';
import image51 from '../../assets/images/image 5 (1).png';
import image52 from '../../assets/images/image 5 (2).png';

export default function Seller() {
  return (
    <>
      <div className="seller-adres">
        <div className="header">
          <div className="container mx-auto px-4 xl:px-12 md:px-4">
            <h1>
              Продажи на Misslighting <br /> по системе FBS (маркетплейс)
            </h1>
            <p>
              Получайте заказы от клиентов, привозите товар и получайте большую
              комиссию от продаж
            </p>
          </div>
        </div>
        <MContainer className="md:py-12 py-8">
          <div className="aside">
            <div className="aside__box">
              <img src={image5} alt="not found" />
              <div className="aside__text">
                <div className="text__title">Выгодно</div>
                <p>
                  Комиссия 1–5%, отсутствие оплаты за складские операции, не
                  надо замораживать сток на складе Misslighting
                </p>
              </div>
            </div>
            <div className="aside__box">
              <img src={image51} alt="not found" />
              <div className="aside__text">
                <div className="text__title">Быстро</div>
                <p>Быстрая загрузка товара на сайт Misslighting</p>
              </div>
            </div>
            <div className="aside__box">
              <img src={image52} alt="not found" />
              <div className="aside__text">
                <div className="text__title">Удобно</div>
                <p>
                  Получайте заказ от клиента и привозите нам заказанный товар в
                  удобный временной диапазон
                </p>
              </div>
            </div>
          </div>
          <div className="seller__title">
            Станьте продавцом и зарабатывайте <br />
            на Birmakon
          </div>
          <ShowAllLink to="/filter" className="show__all">
            Стать продавцом
          </ShowAllLink>
        </MContainer>
      </div>
    </>
  );
}
