import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/_mnews.scss";
import { MContainer, MLink, Link } from "../../element/Elemens";
import { getLastNews, getNewsDetail } from "../../redux/actions/newsActions";
import { FaEye } from "react-icons/fa";
import image4 from "../../assets/images/image 3.png";
import image7 from "../../assets/images/Rectangle 623.png";
import image8 from "../../assets/images/Rectangle 624.png";
import Title from "../../component/Title/Title";
import NavbarMenu from "../../container/NavbarMenu";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import brbalo from "../../assets/images/3d-rendering-loft-luxury-living-room-with-shelf-near-dining-table 2.png";
import { useState } from "react";
const API = "http://admin.chinni-buyum.uz/";

export const MNews = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsDetail(id));
    dispatch(getLastNews());
  }, [id]);

  const { loading } = useSelector((state) => state.news);
  const newsDetail = useSelector((state) => state.news.data);
  const lastNews = useSelector((state) => state.news.last_news);
  const mainDescription0 = String(newsDetail?.description);
  const mainDescription = parse(mainDescription0);

  return (
    <>
      {/* <NavbarMenu /> */}
      <MContainer className="md:py-8 py-4 mnews-page">
        <Title name={newsDetail.name} />
        <div className="mnews__boxes">
          <div className="box">
            <div className="box__image">
              <img src={`${API}${newsDetail.photo}`} alt="not found" />
              <span>{newsDetail.date}</span>
            </div>
            <p>{mainDescription}</p>
            {/* <div className="sidebar">
              <img src={image4} alt="not found" className="sidebar__img" />
              <div className="sidebar__box">
                <img src={image7} alt="not found" />
                <img src={image8} alt="not found" />
              </div>
            </div> */}
            <div className="shows flex flex-wrap">
              <MLink to="">Telegram</MLink>
              <span>|</span>
              <MLink to="">Facebook</MLink>
              <span className="flex items-center">
                <FaEye className="mr-2" size={16} />
                {newsDetail.views}
              </span>
            </div>
          </div>
          <div className="box">
            <div className="box__title">Последние новости</div>
            {lastNews.map((news, index) => (
              <div news={news} key={index} className="late__box">
                <img src={`${API}${news.photo}`} alt="not found" />
                <div className="late__text">
                  <div className="late__title">{news.name}</div>
                  <p>{parse(news.description_mini)}</p>
                  <div className="n__date">
                    <MLink to={"/mnews/" + news.id}>Подробно</MLink>
                    <span>{news.date}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </MContainer>
      {/* <MContainer className="md:py-8 py-4 mnews-page">
        <Title name={"Новости"} />
        <div className="mnews__boxes">
          <div className="box">
            <div className="box__image">
              <img src={brbalo} alt="not found" />
              <span>24.06.2022</span>
            </div>
            <p>
              lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s standard dummy text ever since the 1500s
              lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s standard dummy text ever since the 1500s
              lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s standard dummy text ever since the 1500s
            </p>
            <div className="sidebar">
              <img src={brbalo} alt="not found" className="sidebar__img" />
              <div className="sidebar__box">
                <img src={image7} alt="not found" />
                <img src={image8} alt="not found" />
              </div>
            </div>
            <div className="shows flex flex-wrap">
              <MLink to="">Telegram</MLink>
              <span>|</span>
              <MLink to="">Facebook</MLink>
              <span className="flex items-center">
                <FaEye className="mr-2" size={16} />
                {newsDetail.views}
              </span>
            </div>
          </div>
          <div className="box">
            <div className="box__title">Последние новости</div>
            <div className="late__box">
              <img src={image8} alt="not found" />
              <div className="late__text">
                <div className="late__title">Последные новости</div>
                <p>
                  lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s standard dummy text
                  ever since the 1500s lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s standard
                  dummy text ev
                </p>
                <div className="n__date">
                  <MLink to={"/mnews/24"}>Подробно</MLink>
                  <span>24.01.2002</span>
                </div>
              </div>
            </div>
            <div className="late__box">
              <img src={image8} alt="not found" />
              <div className="late__text">
                <div className="late__title">Последные новости</div>
                <p>
                  lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s standard dummy text
                  ever since the 1500s lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s standard
                  dummy text ev
                </p>
                <div className="n__date">
                  <MLink to={"/mnews/24"}>Подробно</MLink>
                  <span>24.01.2002</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MContainer> */}
    </>
  );
};
