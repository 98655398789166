import React, { useEffect } from "react";
import ratingImg1 from "../../assets/images/unsplash_XZ3EmAIWuz0.png";
import { MContainer, MLink } from "../../element/Elemens";
import "../../assets/scss/_compare.scss";
import { CgSoftwareUpload } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { addCompare, compareList } from "../../redux/actions/compareActions";
import PreLoader from "../../component/PreLoader/PreLoader";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
const API_URL = "http://admin.chinni-buyum.uz";

const Compare = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(compareList());
  }, []);

  const compare_list = useSelector((state) => state.compare.list);
  const { compareStep } = useSelector((state) => state.compare);
  const { loading } = useSelector((state) => state.compare);

  useEffect(() => {
    if (compareStep === true) {
      dispatch(compareList());
    }
  }, [compareStep]);

  return (
    <>
      <MContainer className="c8">
        <Link to="/">Главная страница / </Link>
        <span className="c8">Сравнение товара</span>
      </MContainer>
      <MContainer className="md:pb-16 py-8">
        {loading && <PreLoader />}
        {!loading && (
          <div className="box compare__box py-2 px-2">
            {compare_list.length > 0 ? (
              compare_list.map((compare, idx) => (
                <div key={idx} className="rating__box">
                  <div className="rating__about padding relative">
                    <div className="absolute top-0 right-0 pt-6">
                      <Button
                        onClick={() => {
                          dispatch(addCompare({ product_id: compare.id }));
                          dispatch(compareList());
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        Удалить
                      </Button>
                    </div>
                    <Link to={`/add/${compare.id}`}>
                      <img
                        className="rating__image mr-4"
                        src={`${API_URL}/${compare.photo}`}
                        alt="not found"
                      />
                    </Link>
                    <div className="rating">
                      <div className="rating__title">{compare.name}</div>
                      <div>{compare.price?.toLocaleString("de-De")} сум</div>
                      <div className="rating__number">
                        <span>{compare.rating}</span>
                        <span>Общая оценка</span>
                      </div>
                      {compare.shop && (
                        <MLink
                          to={"/selleradres/" + compare.shop?.id}
                          className="rating__link"
                        >
                          {compare.shop?.name}
                          <CgSoftwareUpload
                            className="ml-2"
                            size={24}
                            fill={"#EE4927"}
                          />
                        </MLink>
                      )}
                    </div>
                  </div>
                  <div className="r__type padding border-top">
                    <div className="w-full">
                      <div className="character__title mb-4">
                        Характеристики
                      </div>
                      {compare.filters?.length > 0 ? (
                        compare.filters?.map((item, idx) => (
                          <>
                            {item.name !== null && (
                              <div
                                key={idx}
                                className={`compare-line lg:w-2/4 w-full flex items-center lg:flex-nowrap flex-wrap justify-between`}
                              >
                                <div>{item.name}</div>
                                <div>{item.value}</div>
                              </div>
                            )}
                          </>
                        ))
                      ) : (
                        <div className="text-sm md:text-base">
                          Нет характеристики
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  height: "500px",
                  margin: "2rem",
                }}
              >
                Сравнений пока нет
              </div>
            )}
          </div>
        )}
      </MContainer>
    </>
  );
};

export default Compare;
