import React from "react";
import "../../assets/scss/_return_product.scss";
import Title from "../../component/Title/Title";
import { MContainer } from "../../element/Elemens";

export default function Politics() {
  return (
    <>
      <div className="border-bottom">
        <MContainer className="r-product md:py-12 py-8">
          <Title name="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ К ОФЕРТЕ ООО  «Chinni buyum»" />
          <div className="product__title">1. Общие сведения</div>
          <p>
            1.1. Настоящая Политика конфиденциальности (далее «Политика»),
            является приложением к Публичной Оферте и определяет порядок
            обработки и защиты персональной информации о Покупателях, которую
            ООО «Chinni buyum» может получить во время использования ими
            сервиса Маркетплейса.
          </p>
          <p>
            1.2. Настоящая Политика разработана в соответствии Законом РУз от
            2.07.2019 г. № ЗРУ-547 «О персональных данных», Законом РУз от
            12.12.2002 г. N 439-II «О принципах и гарантиях свободы информации».
          </p>
          <p>
            1.3. Использование ресурса Chinni-buyum.uz в любой форме означает
            безоговорочное согласие Покупателя с условиями настоящей Политики
            конфиденциальности и указанными в ней условиями обработки его
            персональной информации. В случае несогласия с условиями Политики
            конфиденциальности Покупатель должен воздержаться от использования
            ресурса.{" "}
          </p>
          <p>
            1.4. Политика конфиденциальности (в том числе любая из ее частей)
            может быть изменена без какого-либо специального уведомления и без
            выплаты какой-либо компенсации в связи с этим. Новая редакция
            Политики конфиденциальности вступает в силу с момента ее размещения
            на Chinni-buyum.uz.
          </p>
          <p>
            1.5 Принимая условия настоящей Политики, Покупатель выражает свое
            согласие на обработку своих данных в целях, предусмотренных
            настоящей Политикой, а также на передачу данных о Покупателе третьим
            лицам в случаях, перечисленных в настоящей Политике.{" "}
          </p>
          <p>
            1.6 По общему правилу Продавец не проверяет достоверность
            предоставляемой Покупателями персональной информации. Вместе с тем в
            случаях, предусмотренных Офертой, Покупатель обязан предоставить
            подтверждение достоверности предоставленной им персональной
            информации о себе.
          </p>
          <div className="product__title">2.Термины и определения</div>
          <p>
            2.1. Персональные данные Покупателя - зафиксированная на
            электронном, бумажном и (или) ином материальном носителе информация,
            относящаяся к определенному физическому лицу или дающая возможность
            его идентификации;
          </p>
          <p>
            2.2. Субъект персональных данных (субъект) - физическое лицо, к
            которому относятся персональные данные;
          </p>
          <p>
            2.3. База персональных данных - база данных в виде информационной
            системы, содержащая в своем составе персональные данные;
          </p>
          <p>
            2.4. Обработка персональных данных - реализация одного или
            совокупности действий по сбору, систематизации, хранению, изменению,
            дополнению, использованию, предоставлению, распространению,
            передаче, обезличиванию и уничтожению персональных данных;{" "}
          </p>
          <p>
            2.5. Информация - сведения о лицах, предметах, фактах, событиях,
            явлениях и процессах независимо от источников и формы их
            представления;
          </p>
          <p>
            2.6. Защита информации - меры по предотвращению угроз информационной
            безопасности и устранению их последствий;
          </p>
          <p>
            2.7. Информационная безопасность - состояние защищенности интересов
            личности, общества и государства в информационной сфере;
          </p>
          <p>
            2.8. Конфиденциальная информация - документированная информация,
            доступ к которой ограничивается в соответствии с законодательством;
          </p>
          <p>
            2.9. Массовая информация - документированная информация, печатные,
            аудио, аудиовизуальные и иные сообщения и материалы, предназначенные
            для неограниченного круга лиц;
          </p>
          <p>
            2.10. Третье лицо — любое лицо, не являющееся субъектом,
            собственником и (или) оператором, но связанное с ними
            обстоятельствами или отношениями по обработке персональных данных.
          </p>
          <div className="product__title">3.Обработка персональных данных</div>
          <p>
            3.1. Обработка персональных данных осуществляется в соответствии с
            основными принципами Закона РУз от 2.07.2019 г. № ЗРУ-547 «О
            персональных данных».
          </p>
          <p>
            3.2. Обработка персональных данных Chinni-buyum.uz осуществляется с
            согласия Покупателя на обработку этих данных в связи с выполнением
            услуг, предусмотренных Офертой.{" "}
          </p>
          <p>
            3.3.Настоящая Политика распространяется на следующие виды
            персональных данных:
          </p>
          <p>
            3.3.1. Персональные данные, размещаемые Покупателем, в т.ч. о себе
            самостоятельно при заполнении формы отправки сообщения, иные
            персональные данные, доступ к которым Покупатель предоставляет
            Chinni-buyum.uz через веб-сайт или мобильные приложение, сервисы
            третьих лиц, или персональные данные, размещаемые Покупателем в
            процессе использования ресурса. Покупатель для регистрации и для
            полноценного пользования сервисом, должен предоставить данные:
            фамилия, имя, номер телефона, адрес электронной почты Покупателя.
            Иная информация предоставляется Покупателем на его усмотрение.
          </p>
          <p>
            3.3.2 Данные, автоматически передаваемые Chinni-buyum.uz в процессе
            их использования с помощью, установленного на устройстве Покупателя
            программного обеспечения, в т.ч. IP-адрес, индивидуальный сетевой
            номер устройства (MAC-адрес, ID устройства), электронный серийный
            номер (IMEI, MEID), данные из cookies, информация о браузере,
            операционной системе, времени доступа, поисковых запросах
            Покупателя.
          </p>
          <p>
            3.3.3. Иная информация о Покупателях, сбор и/или обработка которой
            установлены Офертой.{" "}
          </p>
          <p>
            3.4. Запрещается предоставление Покупателем персональных данных
            третьих лиц без полученного от третьих лиц разрешения на такое
            распространение либо, если такие персональные данные третьих лиц не
            были получены самим Покупателем из общедоступных источников
            информации.
          </p>
          <p>
            3.5. Продавец гарантирует Покупателю сохранение конфиденциальности
            следующей персональной информации о Покупателе: — информация о карте
            покупателя (последние 4 цифры); — сведения о покупках и заказах.
            Указанная информация передается Продавцом третьим лицам
            исключительно с целью осуществления оплаты заказа платежной
            системой; иные случаи передачи указанной информации третьим лицам не
            допускаются.
          </p>
          <p>
            3.6. Персональные данные должны быть точными и достоверными, а в
            случае необходимости - изменяться и дополняться.{" "}
          </p>
          <p>
            3.7. Объем и характер обрабатываемых персональных данных Покупателя
            должны соответствовать целям и способам их обработки.{" "}
          </p>
          <p>
            3.8. Персональные данные обрабатываются в форме, допускающей
            идентификацию Покупателя или в обезличенном виде.{" "}
          </p>
          <p>
            3.9. Срок обработки персональных данных не должен превышать срок, в
            течение которого действует согласие Покупателя на обработку его
            персональных данных.
          </p>
          <div className="product__title">
            4. Цели сбора и обработки информации о Покупателях
          </div>
          <p>
            4.1. Продавец осуществляет сбор и обработку только той информации о
            Покупателе, которая необходима для выполнения обязательств
            Chinni-buyum.uz по предоставлению ресурса, ответа на вопрос,
            заданный Покупателем при отправке сообщения с помощью ресурса, а
            также исполнения обязательств, предусмотренных Офертой.
          </p>
          <p>
            4.2. Продавец может использовать персональную информацию Покупателя
            для целей:
          </p>
          <p>
            4.2.1. идентификации стороны в рамках договоров между Покупателем и
            Продавцом.
          </p>
          <p>
            4.2.2. предоставления Покупателю услуг с использованием
            Chinni-buyum.uz и для выполнения своих обязательств, в т.ч.
            уточнения данных платежа, обработки заказов и запросов и дальнейшего
            совершенствования ресурса, разработки новых сервисов и услуг.{" "}
          </p>
          <p>
            4.2.3. информирования Покупателя о появлении новых материалов на
            ресурсе, направления запросов, касающихся использования ресурса,
            обратной связи с Покупателем.
          </p>
          <p>
            4.2.4. информирования Покупателя посредством электронных рассылок.
            Предоставляя свои данные, Покупатель соглашается на получение
            сообщений рекламно-информационного характера и сервисных сообщений
            (рассылка).
          </p>
          <p>
            4.3. Мобильные приложения могут собирать анонимные данные о
            местоположении Покупателя, для того чтобы обеспечить более
            корректную работу с выбором способа платежа. Мобильные приложения
            могут собирать анонимную статистику использования.
          </p>
          <p>
            4.4. При необходимости использовать персональные данные о Покупателе
            в целях, не предусмотренных настоящей Политикой, Chinni-buyum.uz
            запрашивает согласие Покупателя на такие действия.
          </p>
          <p>
            4.5. Основная причина использования информации Покупателей — это
            предоставление и улучшение Chinni-buyum.uz. Кроме того, информация
            используется для обеспечения безопасности сообщества и показа
            рекламы, которая может заинтересовать Покупателя.{" "}
          </p>
          <p>4.6. Информация о Покупателях используется:</p>
          <p>
            4.6.1. Для управления аккаунтом Покупателя в части • Создания и
            управления аккаунтом; • Оказания поддержки и решения запросов; •
            Персонализации цены, предложения скидок и других промоакций,
            осуществления транзакций; • Организации розыгрышей и конкурсов;
          </p>
          <p>
            4.6.2. Для предоставления Покупателям новых возможностей
            Chinni-buyum.uz: • Регистрации и отображения профиля Покупателей в
            новых функциях и приложениях • Администрирования аккаунта в новых
            функциях и приложениях.
          </p>
          <p>
            4.6.4. Для предложения промоакций и управления рекламными и
            маркетинговыми кампаниями • Проведения и измерения эффективности
            рекламных кампаний в сервисах, а также маркетинговых кампаний на
            сторонних сервисах • Информирования Покупателей о продуктах или
            сервисах, которые, могут быть интересны Покупателю.
          </p>
          <p>
            4.6.5. Для улучшения Chinni-buyum.uz и разработки новых приложений:
            • Управления фокус-группами, рыночными исследованиями и опросами. •
            Изучения взаимодействия со службой поддержки для повышения качества
            обслуживания. • Анализа поведение Покупателей в сервисах для их
            улучшения. • Разработки новых функций и сервисов (например, создания
            новой, основанной на интересах функции, в результате запросов,
            полученных от Покупателей)
          </p>
          <p>
            4.6.6. Для предотвращения, обнаружения и борьбы с мошенничеством и
            другими незаконными или несанкционированными действиями: •
            Обнаружения или реагирования на текущие или предполагаемые нарушения
            Оферты через изучение жалоб и общения Покупателей. • Изучения и
            разработки мер противодействия нарушениям Оферты • Сохранения
            данных, связанных с нарушениями Оферты для более быстрого
            реагирования на нарушения и предотвращения их повторения • Общения с
            Покупателями, подавшими жалобы.
          </p>
          <p>
            4.6.7. Для обеспечения юридического соответствия • Соблюдения
            требования законодательства. • Помощи правоохранительным органам.
          </p>
          <div className="product__title">
            5. Меры по защите персональных данных Покупателей
          </div>
          <p>
            5.1. Продавец принимает все необходимые и достаточные
            организационные и технические меры для защиты персональной
            информации о Покупателях от неправомерного или случайного доступа к
            ним, уничтожения, изменения, блокирования, распространения
            персональной информации, а также от иных неправомерных действий с
            ней. К этим мерам относятся, в частности, внутренняя проверка
            процессов сбора, хранения и обработки данных и мер безопасности,
            включая меры по обеспечению физической безопасности данных для
            предотвращения неавторизированного доступа к персональной
            информации.
          </p>
          <p>
            5.2. Хранение персональной информации осуществляется в рамках
            применимого законодательства. Покупатель может удалить свой аккаунт,
            если им принято решение о прекращении пользования сервисом.
            Автоматическое удаление аккаунта происходит, если он будет не
            активен в течение двух лет. После удаления аккаунта удаляется
            персональная информация в следующем порядке : - В целях обеспечения
            защиты и безопасности Покупателей, данные хранятся в течение трех
            месяцев после удаления аккаунта или в течение одного года после
            блокировки. Во время этого периода сохраняется информация о
            Покупателе на случай, если потребуется расследование незаконных или
            вредоносных действий. Хранение информации в течение этого периода
            основано на законных интересах, а также на интересах возможных
            сторонних потерпевших. - По истечении периода хранения в целях
            безопасности удаляются данные Покупателя и остается только
            ограниченная информация для нижеуказанных целей : a) данные
            транзакций в течение 10 лет в соответствии с налоговыми и
            бухгалтерскими требованиями, информацию о банковской карте во время
            всего периода, в течение которого Покупатель может оспорить
            транзакцию, а также «данные трафика» / журналы посещений в течение
            одного года для соблюдения юридических обязательств по хранению
            данных. В течение 5 лет также хранятся записи о согласиях, которые
            предоставляют Покупатели, чтобы подтвердить соблюдение нами
            применимого законодательства. b) записи об обслуживании клиентов и
            вспомогательные данные, а также неточное место скачивания/покупки в
            течение пяти лет для принятия обоснованных решений по обслуживанию
            клиентов, соблюдения прав и защиты в случае претензий; информацию о
            существовании прошлых аккаунтов и подписок, которая удаляется через
            три года после закрытия последнего аккаунта Покупателя, чтобы
            обеспечить надлежащее и точное финансовое планирование и отчетность;
            данные профиля в течение года на случай возможного судебного
            разбирательства для подачи и исполнения судебных исков, а также
            защиты от них; данные, необходимые для предотвращения доступа
            покупателей, которым было запрещено регистрировать новый аккаунт, —
            в течение периода, требуемого для обеспечения безопасности и
            благополучия покупателей. c) информацию на основании законных
            интересов, когда существует нерешенная или возможная проблема,
            претензия или спор, требующие хранить информацию в соответствии с
            юридическими обязательствами или если данные потребуются в рамках
            судебного разбирательства .
          </p>
          <p>
            5.3. При хранении и обработке персональных данных Покупателей
            Chinni-buyum.uz руководствуется Законом РУз от 2.07.2019 г. №
            ЗРУ-547 «О персональных данных».
          </p>
        </MContainer>
      </div>
      <div className="container mx-auto px-4 xl:px-12 md:px-4 r-product__footer">
        <span>*</span>
        <p>
          Настоящие правила носят рекомендательный характер и не ограничивают
          покупателя в правах, связанных с предъявлением требований Продавцу,
          предусмотренных действующим законодательством.
        </p>
      </div>
    </>
  );
}
