import requests from "../../helpers/requests";
import { toast } from "react-toastify";

export const postOcto = (id) => (dispatch) => {
  dispatch({ type: "post_octo_start", payload: id });

  requests
    .postOcto(id)
    .then(({ data }) => {
      dispatch({ type: "post_octo_success", payload: data });
      // window.location.href = data?.octo_pay_url;
    })
    .catch(({ response }) => {
      let message =
        response.data?.errors?.order_id && response.data?.errors?.order_id;
      toast.error(message);

      dispatch({ type: "post_octo_error", payload: message });
    });
};
