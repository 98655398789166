import React, { useEffect, useState } from "react";
import tab1Img from "../../assets/images/Vector (10).png";
import tab2Img from "../../assets/images/Vector (11).png";
import { AiOutlineRight } from "react-icons/ai";
import {
  chatAdmins,
  chatCreate,
  createChatAdmin,
  getMe,
} from "../../redux/actions/userActions";
import { FiSend } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/_message.scss";
import { IconButton } from "@mui/material";
const API = `${process.env.REACT_APP_API_DOMAIN}`;

export default function MessageTab3() {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [file, setFile] = useState();
  useEffect(() => {
    dispatch(chatAdmins());
    dispatch(getMe());
  }, []);

  const messages = useSelector((state) => state.user.chatAdmins);
  const messagesList = useSelector((state) => state.user.adminList);
  const profile = useSelector((state) => state.user.user);

  return (
    <div className="my__message">
      <div className="lists my-8">
        <div className="list mr-4">
          {/* <div className="new__list">
          <i className="next next__page">
            <AiOutlineRight size={30} fill="white" />
          </i>
        </div>
        {admins?.map((admin) => (
          <div key={admin.id} className="new__list">
            <div className="rectangle">
              <img
                className="h-full w-full object-contain"
                src={API + admin.photo}
                alt=""
              />
            </div>
            <p>{admin.name}</p>
          </div>
        ))} */}
        </div>
        <div className="message__shadow">
          <div className="title">По админам</div>
          <div className="message__shadow-message">
            <div>
              {/* <p key={idx}>{message.message}</p> */}
              <div className="admin__text">{messagesList?.message}</div>
            </div>
          </div>
          <div className="container mx-auto px-0">
            <div className="send">
              <form>
                {/* <div className="relative">
                <input
                  onChange={(e) => handleChange(e)}
                  multiple={false}
                  className="absolute top-0 right-0 bottom-0 left-0 opacity-0 h-full w-full"
                  type="file"
                />
                <img src={tab1Img} alt="not found" />
              </div> */}
                <input
                  onChange={(e) => setValue(e.target.value)}
                  type="text"
                  placeholder="Ваше сообщение"
                  value={value}
                />
              </form>
              <IconButton
                onClick={() => {
                  setValue("");
                  dispatch(
                    createChatAdmin({
                      name: profile?.name,
                      email: profile?.email,
                      message: value,
                    })
                  );
                }}
              >
                <FiSend size={25} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
