import React, { useEffect, useState } from "react";
import Title from "../../component/Title/Title";
import Counter from "../../component/Counter/Counter";
import { MContainer, Link } from "../../element/Elemens";
import { toast } from "react-toastify";
import { VscTrash } from "react-icons/vsc";
import "../../assets/scss/_basket.scss";
import {
  postCartAdd,
  postCartRemove,
  postCartMinus,
  postCartClear,
  getCart,
} from "../../redux/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import request from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Navigation, Pagination, Scrollbar, A11y, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import CreditCard from "../info/CreditCard";
import { getDelivery, getPayment } from "../../redux/actions/productActions";
import { getCards } from "../../redux/actions/userActions";
import { Button } from "@mui/material";
import MapPayment from "../add/Map";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getRegions, getRegionsSub } from "../../redux/actions/categoryActions";
import { getLogistSort } from "../../redux/actions/filterActions";
import { BsChevronDown } from "react-icons/bs";
import { postOcto } from "../../redux/actions/octoActions";
import parse from "html-react-parser";
import { getBTCCities, getBTCRegions } from "../../redux/actions/btcActions";

const URL = "http://admin.chinni-buyum.uz/";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  background: "#000",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const octoStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "40%",
  bgcolor: "#f6f6f6",
  background: "#f6f6f6",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Basket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [address, setAddress] = useState(user?.last_address);
  const [payment_id, setPaymentId] = useState(0);
  const [receiver, setReceiver] = useState(0);
  const [name, setName] = useState(user?.name);
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);
  const [delivery_id, setDeliveryId] = useState(0);
  const [card_id, setCardId] = useState();
  const [openBox, setOpenBox] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("");

  const [selectAddres, setSelectAddres] = React.useState("");

  const handleChange = (event) => {
    setSelectAddres(event.target.value);
  };
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627,
  //   },
  //   zoom: 11,
  // };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const [addressMap, setAddressMap] = useState("");
  const [coordinate, setCoordinate] = useState("");
  const cartList = useSelector((state) => state.cart.list);

  const [filter, setFilter] = useState({
    region_id: 0,
    unit_id: 38,
    amount: cartList?.length,
  });

  useEffect(() => {
    dispatch(getDelivery());
    dispatch(getPayment());
    // dispatch(getLogistSort(filter));
    dispatch(getCards());
    // dispatch(getBTCRegions());
  }, []);

  const { oneCart } = useSelector((state) => state.cart);
  const btcRegions = useSelector((state) => state.btc.btcRegions);
  const btcCities = useSelector((state) => state.btc.btcCities);
  const { loading } = useSelector((state) => state.btc);
  // console.log(btcCities);

  useEffect(() => {
    dispatch(getCart());
  }, [oneCart]);

  const [showModal, setShowModal] = useState(false);
  const regions = useSelector((state) => state.category.regions);
  const regions_sub = useSelector((state) => state.category.regions_sub);
  const sortLists = useSelector((state) => state.filter.logistSort);
  const units = useSelector((state) => state.product.unitList);
  const [logist, setLogist] = useState(null);
  const [logistId, setLogistId] = useState(null);
  const [logistId2, setLogistId2] = useState(null);
  const [logist2, setLogist2] = useState(null);
  let logist2Split = logist2?.split(" ");
  let logist2Last = logist2Split && logist2Split[logist2Split.length - 1];
  const [openModal, setOpenModal] = useState(false);
  const [delivery, setDelivery] = useState();
  const [delivery2, setDelivery2] = useState();

  const { step } = useSelector((state) => state.order);
  const { octo_step } = useSelector((state) => state.octo);
  const deliveries = useSelector((state) => state.product.deliveryList);
  const _delivery = deliveries?.find((item) => item.id == delivery_id);
  const payments = useSelector((state) => state.product.paymentList);
  const carts = useSelector((state) => state.user.cartList);

  const [detailCount, setDetailCount] = useState(cartList?.length);
  const region_name = regions_sub?.find((obj) => {
    return obj.id === Number(filter?.region_id);
  });

  const selectedCard = carts?.find((item) => item.id === card_id);

  let total_amount = 0;

  cartList?.forEach((item) => {
    total_amount += item?.price;
  });

  // cart
  const sendOrder = (params) => {
    dispatch({ type: "fetch_send_order_start", payload: params });

    request
      .sendOrder(params)
      .then(({ data }) => {
        dispatch({ type: "fetch_send_order_success", payload: data });
        window.localStorage.removeItem("misslighting_order_id");
        window.localStorage.removeItem("misslighting_order_receipt_id");
        toast.success("Успешно");
        payment_id === 6 && dispatch(postOcto(data?.data?.id));
        // navigate("/deliverycart");
      })
      .catch(({ response }) => {
        let message1 =
          response?.data?.errors?.address && "Адрес: Заполните поле";
        let message2 =
          response?.data?.errors?.delivery_id && "Выберите способ доставки";
        let message3 =
          response?.data?.errors?.payment_id && "Выберите способ оплаты";
        let message4 =
          response?.data?.name === "Unauthorized" && "Зарегистрируйте";
        let message5 =
          response?.data?.errors?.cart && response.data?.errors?.cart;
        toast.error(message1);
        toast.error(message2);
        toast.error(message3);
        toast.error(message4);
        toast.error(message5);

        dispatch({ type: "fetch_send_order_error", payload: message1 });
      });
  };

  const createCheck = (params) => {
    dispatch({ type: "create_check_start", payload: params });

    request
      .createCheck(params)
      .then(({ data }) => {
        dispatch({ type: "create_check_success", payload: data });
      })
      .catch(({ response }) => {
        let message = response && response.data.errors.message;
        toast.error(message);

        dispatch({ type: "create_check_error", payload: message });
      });
  };

  const order_id = window.localStorage.getItem("misslighting_order_id");
  const order_receipt_id = window.localStorage.getItem(
    "misslighting_order_receipt_id"
  );

  const octo = useSelector((state) => state.octo.data);
  const octoUrl = useSelector((state) => state.octo.octoUrl);
  const windowFeatures = "popup";
  const [octoModal, setOctoModal] = useState(false);
  const handleOcto = () => {
    setOctoModal(true);
  };
  const handleCloseOctoModal = () => {
    setOctoModal(false);
  };

  useEffect(() => {
    if (octoUrl) {
      window.location.href = octoUrl;
    }
  }, [octoUrl]);
  useEffect(() => {
    if (step === true) {
      createCheck({ order_id: order_id });
      // if (payment_id === 5) {
      //   createOplata({
      //     order_receipt_id: order_receipt_id,
      //     card_id: card_id,
      //   });
      // }
      // if (payment_id === 6) {
      //   dispatch(
      //     postOcto({
      //       order_id: order_id,
      //     })
      //   );
      //   handleOcto();
      // }
    }
  }, [step]);

  const createOplata = (params) => {
    dispatch({ type: "create_oplata_start", payload: params });

    request
      .createOplata(params)
      .then(({ data }) => {
        dispatch({ type: "create_oplata_success", payload: data });
        navigate("/deliverycart");
        toast.success("Оплачено");
      })
      .catch(({ response }) => {
        let message =
          response.data.errors.card_id && response.data.errors.card_id;
        toast.error(message);
        dispatch({ type: "create_oplata_error", payload: message });
      });
  };

  const [chatWidth, setChatWidth] = useState(undefined);
  const [sidebarTop, setSidebarTop] = useState(undefined);

  useEffect(() => {
    const chatEl = document
      .querySelector(".total__box")
      .getBoundingClientRect();
    setChatWidth(chatEl.width);
    setSidebarTop(chatEl.top);
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  const isSticky = (e) => {
    const chatEl = document.querySelector(".total__box");
    const footer = document.querySelector(".footer__back");
    const footerTop = footer.getBoundingClientRect().top;
    const chatElBottom = chatEl.getBoundingClientRect().bottom;
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 10) {
      chatEl.classList.add("is-sticky");
    } else {
      chatEl.classList.remove("is-sticky");
    }
  };

  let total = total_amount + (_delivery?.price ? _delivery?.price : 0);

  return (
    <>
      {/* <Map
        onClickMapsValue={(mapsValue) => {
          setMapLocation(mapsValue);
          console.log(mapsValue);
        }}
        onClickCoordinateValue={(coordinateValue) => {
          setCoordinate(coordinateValue);
          console.log(coordinateValue);
        }}
      /> */}
      <MContainer>
        <div className="pages-link">
          <Link to="/">Главная страница / </Link>
          <Link to="">Корзина</Link>
        </div>
      </MContainer>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={octoModal}
          onClose={handleCloseOctoModal}
          closeAfterTransition
          center
        >
          <Fade in={octoModal}>
            <Box style={octoStyle}>
              <Typography
                className="!flex !flex-col !items-center !justify-center"
                id="transition-modal-description"
                sx={{ mt: 2 }}
              >
                <a className="text-2xl text-center mt-4" href={octoUrl}>
                  Нажмите чтобы оплатить оплату
                </a>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
      <MContainer className="md:py-8 py-4">
        <Title name="Корзина" />
        <div className="grid grid-cols-10 gap-4 pb-6">
          <div className="col-span-7">
            {cartList?.length > 0
              ? cartList?.map((cart, index) => (
                  <div key={index}>
                    <div className="basket__title">
                      Продавец: {cart.product?.shop?.name}
                    </div>
                    <div className="basket__item">
                      <VscTrash
                        className="korzina-trash"
                        fill="#EE4927"
                        size={28}
                        onClick={() => {
                          dispatch(
                            postCartRemove({ product_id: cart.product.id })
                          );
                        }}
                      />
                      <div className="basket__left">
                        {/* <input checked id="check__input" type="checkbox" /> */}
                        <div className="basket__image relative">
                          <img src={`${URL}${cart.product?.photo}`} alt="" />
                          {cart.product?.discount ? (
                            <div className="basket-discount absolute bottom-0 right-0">
                              {cart.product?.discount}%
                            </div>
                          ) : null}
                        </div>
                        <div className="basket__box">
                          <div className="grid-item">
                            <h4 className="mb-4">{cart.product?.name}</h4>
                            <h5 className="mb-4">
                              <span className="flex flex-wrap">
                                {cart.product?.color?.name}{" "}
                                {cart.productFilter?.map((item) => (
                                  <div
                                    style={{
                                      height: "max-content",
                                      width: "max-content",
                                      color: "#fff",
                                      fontSize: "14px",
                                      padding: "1px",
                                      marginLeft: "2px",
                                      background: "#131e3d",
                                    }}
                                  >
                                    {item?.value_ru}
                                  </div>
                                ))}
                              </span>
                            </h5>
                            <div className="basket__price">
                              {cart.product?.price_old ? (
                                <span className="basket-price_old">
                                  {cart.product?.price_old?.toLocaleString(
                                    "de-De"
                                  )}{" "}
                                  {/* {cart.product?.currency?.name} */}
                                  сум
                                </span>
                              ) : null}
                              <h3>
                                {cart.product?.price?.toLocaleString("de-De")}{" "}
                                {/* {cart.product?.currency?.name} */}
                                сум
                              </h3>
                            </div>
                          </div>
                          <div className="grid-item">
                            {cart?.delivery && (
                              <div className="bank">
                                В Узбекистан через {cart?.delivery}
                              </div>
                            )}
                          </div>
                          <div className="grid-item self-end">
                            <div className="basket__count">
                              <Counter
                                product_id={cart.product?.id}
                                count_product={cart.amount}
                                onClickIncrement={(product_id) => {
                                  dispatch(postCartMinus({ product_id }));
                                }}
                                onClickDecrement={(product_id_count) => {
                                  dispatch(
                                    postCartAdd({
                                      amount: 1,
                                      product_id: product_id_count,
                                    })
                                  );
                                  dispatch(getCart());
                                }}
                              />
                              <h5>
                                Осталось {cart.amount_left}{" "}
                                {cart.product?.unit?.name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : "Ваша корзина пуста"}
            {cartList?.length > 0 && (
              <div className="flex justify-end mt-8">
                <Button
                  onClick={() => {
                    dispatch(postCartClear());
                  }}
                  variant="outlined"
                >
                  Очистить корзину
                </Button>
              </div>
            )}
            <div className="basket__delivery mt-8">
              <div className="basket-50 mt-4">
                <h5>Способ получения товара</h5>
                <div className="inputss mt-4">
                  {deliveries.map((del, idx) => (
                    <div onChange={() => setDeliveryId(del.id)} key={idx}>
                      <input
                        id={`samo${idx}`}
                        className="mr-2"
                        type="radio"
                        name="delivery"
                        value={del.id}
                      />
                      <label htmlFor={`samo${idx}`} style={{ color: "#000" }}>
                        {del.name}
                      </label>
                    </div>
                  ))}
                </div>
                {_delivery && (
                  <div>
                    <div>
                      {_delivery?.price
                        ? `Сумма доставки: ${_delivery?.price} сум`
                        : ""}
                    </div>
                    <div>{parse(String(_delivery?.description))}</div>
                  </div>
                )}
              </div>
              <div className={`basket-50 mt-4`}>
                <h5>Способ доставки</h5>
                <div className="choosed__adress mt-4">
                  {`Узбекистан, ${
                    region_name?.name ? region_name?.name + "," : ""
                  } ${address ? address + "," : ""} ${
                    coordinate?.lat ? coordinate?.lat + "," : ""
                  } ${coordinate?.lng ? coordinate?.lng + "," : ""}` ||
                    "Не выбрано"}
                </div>
                <TextField
                  id="outlined-basic"
                  label="Напишите адрес"
                  className="inputProps !mt-4 select bg-none w-full"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setAddress(e.target.value)}
                />
                {/* <button className="choose__adress mt-4">
                    Выбрать адрес доставки
                  </button> */}
              </div>
            </div>
            <div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style} className="!bg-white">
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Выбрать адрес доставки
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      <MapPayment
                        onClickMapsValue={(mapsValue) => {
                          setAddressMap(mapsValue);
                        }}
                        onClickCoordinateValue={(coordinateValue) => {
                          setCoordinate(coordinateValue);
                        }}
                        clickCoordinate={(coordinate) => {
                          setCoordinate(coordinate);
                        }}
                      />
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>
            {delivery_id === 2 && (
              <div className="md:flex items-center justify-between basket__delivery mt-8">
                <h5
                  className="flex items-center cursor-pointer"
                  onClick={handleOpen2}
                >
                  Выберите BTC
                  <BsChevronDown className="ml-2" size={22} />
                </h5>
                <div>
                  {logist2 !== null
                    ? "В Узбекистан через " + logist2
                    : "Не выбрано"}
                </div>
              </div>
            )}
            <div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={handleClose2}
                closeAfterTransition
                center
              >
                <Fade in={open2}>
                  <Box style={style2} className="!bg-white">
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      <div className="flex bg-white flex-col justify-between h-full AddPageDelivery">
                        <div className="delivery__box">
                          <div className="flex justify-end delivery__cancel"></div>
                          <div className="delivery__title flex items-center justify-between">
                            <h5>Укажите регион</h5>
                          </div>
                          <Tabs>
                            <div className="!grid !grid-cols-2 delivery__select items-center gap-4 my-4">
                              <div className="!w-full self-end">
                                <TextField
                                  disabled
                                  className="select justify-center"
                                  size="small"
                                  value={"Узбекистан"}
                                />
                              </div>
                              <div className="!w-full">
                                <span className="mt-2">Выберите регион</span>
                                <select
                                  className="select mt-1"
                                  onChange={(e) => {
                                    // dispatch(getBTCCities(e.target.value));
                                    const selectedRegionId = e.target.value;
                                    const selectedRegion = regions?.find(
                                      (region) => region?.id == selectedRegionId
                                    );
                                    setSelectedRegion(selectedRegion);
                                    let newFilter = {
                                      ...filter,
                                      unit_id: 37,
                                      region_id: e.target.value,
                                      amount: cartList?.length,
                                    };

                                    setFilter(newFilter);
                                    dispatch(getLogistSort(newFilter));
                                  }}
                                >
                                  <option value="">Выберите</option>
                                  {regions?.map((region) => (
                                    <option value={region?.id} key={region?.id}>
                                      {region?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="!w-full">
                                <span className="mt-2">Выберите район</span>
                                <select
                                  onChange={(e) => {
                                    let newFilter = {
                                      ...filter,
                                      unit_id: 37,
                                      region_id: e.target.value,
                                      amount: cartList?.length,
                                    };

                                    setFilter(newFilter);
                                    dispatch(getLogistSort(newFilter));
                                  }}
                                  className="select mt-1"
                                  name=""
                                  id=""
                                >
                                  <option value="">Выберите</option>
                                  {selectedRegion?.childs?.map((item) => (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* <div className="!w-full">
                                <span className="mt-2">Напишите адрес</span>
                                <TextField
                                  id="outlined-basic"
                                  label="Напишите адрес"
                                  className="inputProps select bg-none w-full"
                                  variant="outlined"
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </div> */}
                            </div>
                            <TabPanel className="mt-4">
                              {sortLists?.map((logist, idx) => (
                                <label
                                  key={idx}
                                  onChange={(e) => {
                                    setLogist(e.target.value);
                                    setLogistId(
                                      logist?.logistRegions?.tariffs?.id
                                    );
                                  }}
                                  className="flex items-center mb-3 punkt"
                                >
                                  <input
                                    className="mr-4"
                                    value={
                                      logist.name +
                                      " " +
                                      logist.logistRegions?.tariffs?.price
                                    }
                                    name="company"
                                    type="radio"
                                  />
                                  {logist.name} -{" "}
                                  {logist.logistRegions?.tariffs?.price} (
                                  {logist.logistRegions?.tariffs?.unit})
                                </label>
                              ))}
                            </TabPanel>
                          </Tabs>
                        </div>
                        <div className="delivery__bottom">
                          <button
                            onClick={handleOpen}
                            className="delivery__pick mb-2 w-full"
                          >
                            Выбрать на карте
                          </button>
                          <div className="flex justify-between">
                            <button
                              onClick={() => {
                                setDelivery2(delivery);
                                setOpen2(false);
                                setLogist2(logist);
                                setLogistId2(logistId);
                              }}
                              className="save"
                              type="submit"
                            >
                              Сохранить
                            </button>
                            <button
                              onClick={() => {
                                setOpen2(false);
                                setFilter({
                                  ...filter,
                                  amount: cartList?.length,
                                  region_id: null,
                                  unit_id: 38,
                                });
                                getLogistSort();
                                setDelivery2();
                              }}
                              className="cancel"
                            >
                              Отменить
                            </button>
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>
            <div className="grid grid-cols-2 a111 gap-4">
              <div className="payment__item">
                <div className="payment__box">
                  <h5>Способ оплаты</h5>
                  <p className="c8">
                    Для оформления заказа , выбрат адрес доставки
                  </p>
                  <div className="inputss">
                    {payments?.map((payment, idx) => (
                      <div onChange={() => setPaymentId(payment.id)} key={idx}>
                        <input
                          id={`input${idx}`}
                          className="mr-2"
                          type="radio"
                          name="payments"
                        />
                        <label
                          htmlFor={`input${idx}`}
                          style={{ color: "#000" }}
                        >
                          {payment.name}
                        </label>
                      </div>
                    ))}
                  </div>
                  {payment_id === 5 && (
                    <>
                      <Swiper
                        modules={[
                          Navigation,
                          Pagination,
                          Scrollbar,
                          A11y,
                          Lazy,
                        ]}
                        spaceBetween={10}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        lazy={true}
                      >
                        {carts?.map((cart, index) => (
                          <SwiperSlide
                            className={`swiper-slide bg-white shadow-2xl`}
                            key={index}
                          >
                            <CreditCard
                              onClickCartId={(id) => setCardId(id)}
                              cart={cart}
                            />
                          </SwiperSlide>
                        ))}
                        <div>
                          {card_id &&
                            "card number: " + selectedCard.card_number}
                        </div>
                        <div>
                          {card_id &&
                            "card phone number: " +
                              selectedCard.card_phone_number}
                        </div>
                      </Swiper>
                      <Button variant="outlined">
                        <Link to="/info">Добавить карту</Link>
                      </Button>
                    </>
                  )}
                  {/* {payment_id === 37 && (
                    <img
                      src="https://docs.click.uz/wp-content/themes/click_help/assets/images/logo.png"
                      alt=""
                    />
                  )} */}
                </div>
              </div>
              <div>
                <div className={`login__box`}>
                  <div className="login__title">
                    <h4>Ваши данные</h4>
                    {/* <button>Войти</button> */}
                    {user.phone ? null : <Link to="/firstregister">Войти</Link>}
                  </div>
                  <div className="message mt-4">
                    <label htmlFor="input6">Получать буду не я</label>
                    <input
                      onClick={() => setOpenBox((value) => !value)}
                      id="input6"
                      type="checkbox"
                      onChange={(e) => setReceiver(e.target.checked ? 1 : 0)}
                    />
                  </div>
                  <div className={`${openBox ? "!hidden" : "!block"}`}>
                    <div className="flex justify-between mt-4">
                      <div className="login__item mr-2">
                        <h5>Имя</h5>
                        <input
                          type="text"
                          value={user.name}
                          placeholder="Ваше имя"
                          // onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="login__item ml-2">
                        <h5>Фамилия</h5>
                        <input
                          type="text"
                          placeholder="Ваш фамилия"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="login__item w-full mt-4 mr-2">
                        <h5>Контактный телефон</h5>
                        <input
                          type="tel"
                          value={user.phone}
                          placeholder="Телефон"
                          // onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      {/* <div className="login__item mt-4 ml-2">
                      <h5>Адрес</h5>
                      <input
                        type="text"
                        placeholder="Ваш адрес"
                        onChange={(e) => setAddress(e.target.value)}
                        value={user?.last_address}
                      />
                    </div> */}
                    </div>
                    <div className="login__item mt-4">
                      <h5>Электронная почта</h5>
                      <input
                        type="email"
                        value={user.email}
                        placeholder="E-mail"
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {/* <div className="login__agree mt-4">
                      <input defaultChecked id="input5" type="checkbox" />
                      <label htmlFor="input5" className="c8">
                        Получать эксклюзивные скидки вSMS‑рассылке от Birmakon
                      </label>
                    </div> */}
                  </div>
                  <div className={`${openBox ? "!block" : "!hidden"}`}>
                    <div className="flex justify-between mt-4">
                      <div className="login__item mr-2">
                        <h5>Имя</h5>
                        <input
                          type="text"
                          placeholder={"Ваше имя"}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="login__item ml-2">
                        <h5>Фамилия</h5>
                        <input
                          type="text"
                          placeholder="Ваш фамилия"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="login__item mt-4 mr-2">
                        <h5>Контактный телефон</h5>
                        <input
                          type="tel"
                          placeholder={"Телефон"}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      {/* <div className="login__item mt-4 ml-2">
                      <h5>Адрес</h5>
                      <input
                        type="text"
                        placeholder="Ваш адрес"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div> */}
                    </div>
                    <div className="login__item mt-4">
                      <h5>Электронная почта</h5>
                      <input
                        type="email"
                        placeholder="E-mail"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {/* <div className="login__agree mt-4">
                      <input defaultChecked id="input5" type="checkbox" />
                      <label htmlFor="input5">
                        Получать эксклюзивные скидки вSMS‑рассылке от Birmakon
                      </label>
                    </div> */}
                  </div>
                </div>
                {/* <button className="total__button mt-8 !py-4 !w-5/6 !ml-auto">
                  Оплатить
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="total__box">
              <div className="flex justify-between mt-4">
                <h5 className="total__left">Товары ({cartList?.length})</h5>
                <p className="total__right">
                  {total_amount?.toLocaleString("de-De")} сум
                </p>
              </div>
              <div className="flex justify-between flex-wrap mt-2">
                <h5 className="total__delivery">Стоимость доставки:</h5>
                <button className="total__pick">
                  {_delivery?.price
                    ? _delivery?.price?.toLocaleString("de-De") + " сум"
                    : 0}
                </button>
              </div>
              <div className="flex justify-between items-center flex-wrap mt-4">
                <h5 className="total__title">Итого</h5>
                <div>{total.toLocaleString("de-De")} сум</div>
              </div>
              <div className="my-5">
                <button
                  onClick={() => {
                    if (logistId2) {
                      sendOrder({
                        // address: user?.last_address || address,
                        address: address,
                        map_location: `Узбекистан, ${
                          address ? address + "," : ""
                        } ${region_name?.name ? region_name?.name + "," : ""} ${
                          coordinate?.lat ? coordinate?.lat + "," : ""
                        } ${coordinate?.lng ? coordinate?.lng + "," : ""}`,
                        name,
                        lastName,
                        email,
                        phone,
                        receiver,
                        delivery_id,
                        logist_id: logistId2,
                        payment_id,
                      });
                    }
                    if (!logistId2) {
                      sendOrder({
                        // address: user?.last_address || address,
                        address: address,
                        map_location: `Узбекистан, ${
                          address ? address + "," : ""
                        } ${region_name?.name ? region_name?.name + "," : ""} ${
                          coordinate?.lat ? coordinate?.lat + "," : ""
                        } ${coordinate?.lng ? coordinate?.lng + "," : ""}`,
                        name,
                        lastName,
                        email,
                        phone,
                        receiver,
                        delivery_id,
                        payment_id,
                      });
                    }
                    dispatch(getCart());
                  }}
                  className="total__button"
                >
                  Заказать
                </button>
              </div>
              <div className="total__agree">
                <label className="basket__spec flex items-center">
                  {/* <input defaultChecked className="mr-2" type="checkbox" /> */}
                  <span>
                    Правил пользования торговой площадкой и правилами возврата
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </MContainer>
    </>
  );
};

export default Basket;
